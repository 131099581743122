<template>
	<div>
		<div v-if="!readonly" class="d-flex justify-end">
			<v-btn color="warning" small class="" title="" @click="addItem">
				<v-icon left>
					mdi-plus
				</v-icon> <translated-text :section-suffix="transSs">nuovo</translated-text>
			</v-btn>
		</div>


		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left" v-text="gTranslate('Titolo', {sectionSuffix:transSs})"></th>
						<th class="text-left" v-text="gTranslate('Data', {sectionSuffix:transSs})"></th>
						<th class="text-left" v-text="gTranslate('Tipologia', {sectionSuffix:transSs})"></th>
						<th class="text-left" v-text="gTranslate('Effettuato da', {sectionSuffix:transSs})"></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in decodedList" :key="item.Id" @click="editItem(item)">
						<td>{{ item.Titolo }}</td>
						<td>{{ gStandardDate(item.DataCreazione) }}</td>
						<td>{{ decodificaTipoColloquio(item.CodiceTipoAudit) }}</td>
						<td>{{ item.ReferenteExt }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<debug-container>
			<v-row>
				<v-col cols="6">
					<debug-panel label="BINDING (lista audit della persona corrente)">
						{{ value }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="BINDING DECODIFICATO">
						{{ decodedList }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="this.decodifiche.NomiUtenti">
						{{ decodifiche.NomiUtenti }}
					</debug-panel>
				</v-col>
			</v-row>
		</debug-container>

		<!-- inizio dialog -->
		<v-dialog v-if="currentAudit" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-if="readonly" v-text="gTranslate('Visualizza audit', {sectionSuffix:transSs})" />
					<v-toolbar-title v-else v-text="(currentAudit.Id<0 ? gTranslate('Nuovo audit', {sectionSuffix:transSs}) : gTranslate('Modifica audit', {sectionSuffix:transSs}))" />
					<v-spacer />
					<v-toolbar-items v-if="readonly">
						<v-btn text dark @click="dialog = false" v-text="gTranslate('Chiudi', {sectionSuffix:transSs})" />
					</v-toolbar-items>
					<v-toolbar-items v-else>
						<v-btn text dark @click="updateItem" v-text="currentAudit.Id<0 ? gTranslate('Aggiungi', {sectionSuffix:transSs}) : gTranslate('Modifica', {sectionSuffix:transSs})" />
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-form :readonly="readonly">
						<v-row :dense="compactVisualization">
							<v-col v-if="$vuetify.breakpoint.name === 'xs'" cols="12" lg="12">
								<span class="headline">{{ personaCorrente.FullName }}</span>
							</v-col>
						</v-row>
						<v-row :dense="compactVisualization">
							<v-col cols="12" lg="6">
								<v-text-field ref="autofocus" v-model="currentAudit.Titolo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Titolo', {sectionSuffix:transSs})" />
							</v-col>
							<v-col cols="12" lg="6">
								<v-select v-model="currentAudit.IdColloquio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Tipo colloquio', {sectionSuffix:transSs})" :items="decodifiche.TipoColloquio" item-value="Id" item-text="TipoColloquio" @change="loadTipologieColloquio(currentAudit.IdColloquio)" />
							</v-col>
							<v-col cols="12" lg="6">
								<hr-date-picker v-model="currentAudit.DataCreazione" :readonly="readonly" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Data', {sectionSuffix:transSs})" />
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field v-model="currentAudit.ReferenteExt" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Utente che ha gestito il colloquio', {sectionSuffix:transSs})" :disabled="true" />
							</v-col>
							<template v-if="currentColloquio">
								<v-col cols="12">
									<div class="d-flex align-center">
										<span class="title mr-5 primary--text" v-text="gTranslate('Scopo colloquio', {sectionSuffix:transSs})"></span><v-divider />
									</div>
									<p class="caption mb-1 text-right" v-text="currentColloquio.ScopiObiettivi" />
									<v-textarea v-model="currentAudit.NoteScopoColloquio" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow :label="gTranslate('Note scopo colloquio', {sectionSuffix:transSs})" />
								</v-col>
								<v-col cols="12">
									<div class="d-flex align-center">
										<span class="title mr-5 primary--text" v-text="gTranslate('Svolgimento', {sectionSuffix:transSs})"></span><v-divider />
									</div>
									<p class="caption mb-1 text-right" v-text="currentColloquio.Svolgimento" />
									<v-textarea v-model="currentAudit.NoteSvolgimentoColloquio" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow :label="gTranslate('Note svolgimento', {sectionSuffix:transSs})" />
								</v-col>
								<v-col cols="12">
									<div class="d-flex align-center">
										<span class="title mr-5 primary--text" v-text="gTranslate('Conclusioni', {sectionSuffix:transSs})"></span><v-divider />
									</div>
									<p class="caption mb-1 text-right" v-text="currentColloquio.Conclusioni" />
									<v-textarea v-model="currentAudit.NoteConclusioneColloquio" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow :label="gTranslate('Note conclusioni', {sectionSuffix:transSs})" />
								</v-col>
								<v-col cols="12">
									<div class="d-flex align-center mb-5">
										<span class="title mr-5 warning--text" v-text="gTranslate('Attenzione', {sectionSuffix:transSs})"></span><v-divider />
									</div>
									<v-textarea v-model="currentAudit.NoteAttenzione" single-line :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" rows="1" auto-grow :label="gTranslate('Note attenzione', {sectionSuffix:transSs})" />
								</v-col>
							</template>
						</v-row>
					</v-form>
					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12" lg="6">
								<debug-panel label="Current Audit">
									{{ currentAudit }}
								</debug-panel>
							</v-col>
							<v-col cols="12" lg="6">
								<debug-panel label="Current Colloquio">
									{{ currentColloquio }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->			
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la pagina di edit PERSONA - gestisce gli audits nella tab omonima  ********
***********************************************************************************************
***********************************************************************************************
* TODO: documentare meglio i vari passaggi che tra qualche mese non ti ricordi una beata fava
***********************************************************************************************
*/
import Vue from 'vue'
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
import HrDatePicker from '@/components/hr-date-picker'
import moment from 'moment'
export default {
	name: 'HrPersonaAudits',
	components: { DebugContainer, DebugPanel, HrDatePicker },
	props: {
		// la proprietà bindata direttamente (ovvero legata al v-model) corrisponde a "AuditsList" ed è appunto l'unica che verrà modificata dal componente e "restituita" alla pagina persona
		value: {
			type: Array,
			required: true,
			default: () => {return []}
		},
		// disattiva la possibilità di modificare i dati
		readonly: {
			type: Boolean,
			default: () => {return false}
		},			
	},
	data: () => {
		return {
			transSs:'.tabAudit',
			dialog: false,
			// audit correntemente visualizzato nella modale
			currentAudit: {},
			// tipo colloquio correntemente visualizzato nella modale (selezionato dalla tendina apposita)
			currentColloquio: {},
		}
	},
	computed: {
		appSetup() { return window.setup },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		persone() { return this.$store.getters['persone'] },
		personaCorrente() { return this.$store.getters['personaCorrente'] },
		currentUser() { return this.$store.getters['currentUser'] },
		// decodifica i dati di value (ovvero lista audits) per una visualizzazione più agevole
		decodedList() {
			var decoupledValue = JSON.parse(JSON.stringify(this.value))
			decoupledValue.forEach(i => {
				// attenzione che si tocca questa funzione va aggiornata anche in audits.vue e viceversa
				// decodifica nel campo preesistente ReferenteExt
				// se ReferenteColloquio è uguale a -1 il record è nuovo e quindi viene decodificato il nome dell'utente (non persona) a partire dal campo IdUtenteReferenteColloquio
				// se invece è maggiore di 0 significa che il record è vecchio e il nome va preso decodificando ReferenteColloquio sulle persone (non sugli utenti)
				// infine se ReferenteColloquio==0 non possiamo decodificare una beata fava :-)
				var nomeReferenteColloquio = '*'
				if(i.ReferenteColloquio===-1) {
					var dummy1 = this.decodifiche.NomiUtenti.find(item => { return item.Id === i.IdUtenteReferenteColloquio })
					nomeReferenteColloquio = dummy1 ? dummy1.FullName : '- u:' + i.IdUtenteReferenteColloquio + '-'
				} else if(i.ReferenteColloquio > 0) {
					var dummy2 = this.persone.find(item => { return item.Id === i.ReferenteColloquio })
					nomeReferenteColloquio = dummy2 ? dummy2.FullName : '- p:' + i.ReferenteColloquio + '-'
				} else {
					nomeReferenteColloquio = '--'
				}
				this.$set(i, 'ReferenteExt', nomeReferenteColloquio)
			})
			return decoupledValue
		},
	},
	mounted() {
	},
	methods: {
	
		decodificaTipoColloquio(id) {
			if(this.decodifiche && this.decodifiche.TipoColloquio) {
				var dummy = this.decodifiche.TipoColloquio.find(item => { return item.Id == id }) // ATTENZIONE !!! va usato == e NON === in quanto le due tabelle di origine hanno tipi diversi (Audits.CodiceTipoAudit è string mentre TipoColloquio.Id è un int)
				if(dummy) return dummy.TipoColloquio
			} 
			return '-'
		},
		decodificaNomeReferenteColloquio(id) {
			var dummy = this.persone.find(item => { return item.Id === id })
			if(dummy) return dummy.FullName
			return '-'
		},
		// apre la modale è imposta l'autofocus
		openDialog() {
			this.dialog = true
			setTimeout(() => {
				var afdummy = this.$refs.autofocus
				if (afdummy) afdummy.focus()
			}, 300)
		},
		// carica in currentColloquio l'id richiesto
		loadTipologieColloquio(id) {
			var dummy = this.decodifiche.TipoColloquio.find(item => { return item.Id == id })
			if(!dummy) dummy = this.decodifiche.TipoColloquio[0] // se non trova l'id richiesto carica il primo della lista
			this.currentColloquio = dummy
		},
		// aggiunge nuovo audit, carica currentAudit e apre modale
		async addItem() {
			this.loadTipologieColloquio(0) // carica il primo tipo colloquio disponibile (passando n id che non essite viene appunto caricato il primo elemento della lista )
			if (this.currentColloquio) {
				var negativeId = 0
				await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
				var idReferenteColloquio = this.currentUser.Id
				var	nomeReferenteColloquio = this.currentUser.Nome + ' ' + this.currentUser.Cognome
				this.currentAudit = {
					Id: negativeId,
					DataCreazione: moment().format('YYYY-MM-DD') + 'T00:00:00',
					Titolo: '',
					CodiceTipoAudit: '',
					IdColloquio: this.currentColloquio.Id, // carica l'id del primo tipo colloquio disponibile
					TestoDescrittivo: '',
					ReferenteColloquio: -1, // non più in uso lo teniamo solo per i dati vecchi (refs #873) il valore caricato se
					IdUtenteReferenteColloquio: idReferenteColloquio, // salva l'id dell'utente (non persona!) che effettua l'aggiunta dell'audit (refs #873)
					ReferenteExt: nomeReferenteColloquio, // nome utente referente
					IdPersona: this.personaCorrente.Id,
					NoteScopoColloquio: '',
					NoteSvolgimentoColloquio: '',
					NoteConclusioneColloquio: '',
					NoteAttenzione: ''
				}
				this.openDialog()
			} else {
				let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
				let swText =  this.gTranslate('La lista "Guida colloqui" è vuota!', {sectionSuffix: this.transSs})
				this.$swal(swTitle, swText, 'error')
			}
		},
		// modifica audit selezionato nella tabella, carica currentAudit e apre modale
		editItem(item) {
			Vue.set(this, 'currentAudit', JSON.parse(JSON.stringify(item)))
			this.loadTipologieColloquio(this.currentAudit.IdColloquio) // carica il tipo colloquio ricnjiesto
			this.openDialog()
		},
		// "emit" dato aggiornato 
		updateItem() {
			/* copio l'IdColloquio corrente nel vecchio campo CodiceTipoAudit 
			 ricordo che "CodiceTipoAudit" è un vecchio campo string mantenuto per compatibilità 
			 mentre "IdColloquio" è un int not-mapped generato al volo per velocizzare le operazioni 
			 In fase di salvataggio però conta solo il vecchio campo */
			this.currentAudit.CodiceTipoAudit = '' + this.currentAudit.IdColloquio
			var add = true
			// itero l'array Value e se trovo l'audit di pari Id lo sostituisco
			this.value.forEach((item, index) => {
				if (item.Id === this.currentAudit.Id) {
					Vue.set(this.value, index, this.currentAudit)
					add = false
					return 
				}
			})
			if(add) this.value.unshift(this.currentAudit)
			this.dialog = false
		},
	},
}
</script>

<style scoped lang="less">

</style>