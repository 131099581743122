<template>
	<div>
		<div v-if="!readonly" class="d-flex justify-end mb-5">
			<v-btn color="warning" small class="" title="" @click="addItem">
				<v-icon left>
					mdi-plus
				</v-icon> {{ gTranslate('Add files', {sectionSuffix:transSs}) }} 
			</v-btn>
		</div>

		<v-list two-line>
			<template v-for="(item, index) in value">
				<v-divider :key="index" />
				<v-list-item :key="item.Id" :inactive="!item.ExistAllegato || item.Id <= 0" @click="open(item)">
					<v-list-item-avatar>
						<v-icon large color="primary" :disabled="!item.ExistAllegato" v-text="item.IconAllegato" />
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							{{ item.NomeCompletoAllegato }}
							<v-btn icon x-small class="ml-5" :disabled="!item.ExistAllegato || item.Id <= 0" :title="item.ExistAllegato ? gTranslate('Scarica allegato', {sectionSuffix:transSs}) : gTranslate('L\'allegato non esiste', {sectionSuffix:transSs})" @click.stop="download(item)">
								<v-icon v-if="item.ExistAllegato" color="accent">
									mdi-download
								</v-icon><v-icon v-else color="error">
									mdi-alert-circle
								</v-icon>
							</v-btn>
						</v-list-item-title>
						<v-list-item-subtitle>
							{{ item.Descrizione }} 
							<v-btn v-if="!readonly" color="accent" x-small :title="gTranslate('Modifica descrizione allegato', {sectionSuffix:transSs})" icon @click.stop="openModificaDescrizioneAllegato(item, index)">
								<v-icon x-small>mdi-pencil</v-icon>
							</v-btn>
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-list-item-action-text class="text-right">
							<div class="d-flex">
								<div>
									<span class="caption">{{ gStandardDate(item.DataInserimento) }}</span><br>
									<v-chip v-if="item.ExistAllegato" x-small>
										{{ item.SizeAllegato | fileSize }}
									</v-chip>
									<v-chip v-else x-small color="error">
										{{ gTranslate('Il file non esiste', {sectionSuffix:transSs}) }}
									</v-chip>
								</div>
								<div>
									<v-btn v-if="!readonly" color="accent" x-small :title="gTranslate('Elimina allegato', {sectionSuffix:transSs})" class="ml-5" icon @click.stop="eliminAllegato(index)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
							</div>
						</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
			</template>
		</v-list>
		
		<debug-container>
			<v-row>
				<v-col cols="12">
					<debug-panel label="BINDING (lista allegati della persona corrente)">
						{{ value }}
					</debug-panel>
				</v-col>
			</v-row>
		</debug-container>

		<!-- inizio dialog upload -->
		<v-dialog v-model="dialogUpload" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogUpload = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialogUpload = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ gTranslate('Add files', {sectionSuffix:transSs}) }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="!upload.MultipleFileData || loading" :loading="loading" @click="uploadAllegato">
							{{ gTranslate('Carica', {sectionSuffix:transSs}) }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col v-if="$vuetify.breakpoint.name === 'xs'" cols="12" lg="12">
							<span class="headline">{{ personaCorrente.FullName }}</span>
						</v-col>
					</v-row>
					<v-row :dense="compactVisualization">
						<v-col cols="12">
							<v-file-input v-model="upload.MultipleFileData" 
								:filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Allegati', {sectionSuffix:transSs})" 
								counter
								multiple
								show-size
								small-chips
								truncate-length="24"
							/>
						</v-col>
					</v-row>
					<v-row v-if="error" dense>
						<v-col cols="12">
							<v-alert text type="error">
								<span class="title">{{ error }}</span><br><span class="caption">{{ errorDetail }}</span>
							</v-alert>
						</v-col>
					</v-row>
					
					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<debug-panel label="Upload data">
									{{ upload }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog upload -->		

		<!-- inizio dialog edit descrizione  -->
		<v-dialog v-model="dialogEdit" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogEdit = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialogEdit = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ gTranslate('Modifica descrizione', {sectionSuffix:transSs}) }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="!currentFileDescription || loading" :loading="loading" @click="modificaDescrizioneAllegato">
							{{ gTranslate('Modifica', {sectionSuffix:transSs}) }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col v-if="$vuetify.breakpoint.name === 'xs'" cols="12" lg="12">
							<span class="headline">{{ personaCorrente.FullName }}</span>
						</v-col>
					</v-row>
					<v-row :dense="compactVisualization">
						<v-col cols="12">
							<v-text-field ref="autofocus" v-model="currentFileDescription" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Descrizione', {sectionSuffix:transSs})" />
						</v-col>
					</v-row>
					
					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<debug-panel label="Desc data">
									index: {{ currentFileIndex }}
									desc: {{ currentFileDescription }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog editi descrizione -->			
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la pagina di edit PERSONA - gestisce gli allegati  ************************
***********************************************************************************************
*/
// import Vue from 'vue'
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
// import moment from 'moment'
export default {
	transSs:'.tabDocumenti',
	name: 'HrPersonaAllegati',
	components: { DebugContainer, DebugPanel },
	props: {
		// la proprietà bindata direttamente (ovvero legata al v-model) corrisponde a "AllegatiList" ed è appunto l'unica che verrà modificata dal componente e "restituita" alla pagina persona
		value: {
			type: Array,
			required: true,
			default: () => {return []}
		},
		// disattiva la possibilità di modificare i dati
		readonly: {
			type: Boolean,
			default: () => {return false}
		},			
	},
	data: () => {
		return {
			transSs: 'tabAllegati',
			dialogUpload: false,
			dialogEdit: false,
			loading: false,
			error: '',
			errorDetail: '',
			currentFileDescription: '',
			currentFileIndex: -1,
			upload: {
				MultipleFileData: null,
			}
		}
	},
	computed: {
		appSetup() { return window.setup },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		personaCorrente() { return this.$store.getters['personaCorrente'] },
		currentUser() { return this.$store.getters['currentUser'] },
	},
	mounted() {
	},
	methods: {
		// carica nuovo allegato
		addItem() {
			this.error = ''
			this.upload.FileDescription = ''
			this.upload.MultipleFileData = null
			this.openDialogUpload()
		},
		// apre la modale per l'upload multiplo è imposta l'autofocus
		openDialogUpload() {
			this.dialogUpload = true
			setTimeout(() => {
				var afdummy = this.$refs.autofocus
				if(afdummy) afdummy.focus()
			}, 300)
		},
		// modifica la descrizione dell'allegato	
		openModificaDescrizioneAllegato(item, index)	 {
			this.currentFileIndex = index
			this.currentFileDescription = item.Descrizione
			this.dialogEdit = true
		},
		modificaDescrizioneAllegato() {
			if(this.currentFileIndex>=0) {
				this.value[this.currentFileIndex].Descrizione = this.currentFileDescription
			}
			this.dialogEdit = false
		},
		// elimina un elemento in value (occhio che viene chiamato by index)
		// TODO: per l'eliminazione lato server faremo una servizio di pulizia notturno che cancellerà tutti gli orfani e i vecchi temporanei
		eliminAllegato(index) {
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Sei sicuro di voler eliminare l\'allegato', {sectionSuffix: this.transSs})
			var item = this.value[index]
			this.$swal(swTitle, swText + ' "'+item.NomeCompletoAllegato+'" ?', 'warning', {
				buttons: {
					ok: swYes,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					this.value.splice(index,1)
				}
			}).catch((error) => {
				console.error(error)
			})			
		},
		// apre l'allegato
		open(item) {
			if(item.Id<=0) return // per ora non possiamo aprire (o scaricare) documenti appena caricati (il file ci sarebbe anche ma l'id del record che passiamo ovviamente non trova riscontro lato server... ci penseremo)
			this.$store.dispatch('openAllegato', {Id: item.Id, IdPersona: item.IdPersona}).catch(error => {
				console.error(error)
			})    
		},
		// scarica l'allegato
		download(item) {
			this.$store.dispatch('downloadAllegato', {Id: item.Id, IdPersona: item.IdPersona}).catch(error => {
				console.error(error)
			})    
		},
		async uploadAllegato() {
			this.upload.MultipleFileData.forEach(element => {
				this.error = ''
				this.loading = true
				let formData = new FormData()
				formData.append('fileData', element)
				var negativeId = 0
				this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
				// console.log('formData.getAll', formData.getAll('uploadForm'))
				this.$store.dispatch('uploadAllegato', formData).then(allegato => {
					// aggiunge all'oggetto restituito (che ricordo essere di tipo PersnonaAllegato) i dati mancanti e poi lo aggiunge al "value"
					allegato.Id = negativeId
					allegato.IdPersona = this.personaCorrente.Id
					allegato.Descrizione = this.upload.FileDescription
					this.value.unshift(allegato)
					this.dialogUpload = false
					this.loading = false
				}).catch(error => {
					console.error(error)
					this.error = 'Errore durante il caricamento del file'
					this.errorDetail = error
					this.loading = false
				}) 
			})
		}
	},
}
</script>

<style scoped lang="less">

</style>