<template>
	<div>
		<v-alert v-if="!competenze" type="error" outlined text class="ml-6">lista competenze non inizializzata</v-alert>
		<!-- 1° ciclo sui job -->
		<div v-for="job in jobsListExt" :key="job+'-'">
			<div class="mb-10">
				<div :class="job ? '' : 'mt-10'" class="d-flex align-center justify-space-between">
					<div class="headline" :class="job ? 'primary--text' : 'warning--text'">
						{{ decodificaJob(job).DescrizioneJob }}
					</div>
					<v-btn v-if="!job && !readonly" color="warning" small :title="gTranslate('Aggiungi una valutazione extra job', {sectionSuffix:transSs})" @click="addValutazioneExtraJob">
						<v-icon left>
							mdi-plus
						</v-icon> <translated-text :section-suffix="transSs">Aggiungi</translated-text>
					</v-btn>
				</div>
				<!-- 2° ciclo sulle competenze per job -->
				<v-list v-if="job" two-line>
					<template v-for="competenza in competenzePerJob(job)">
						<v-list-item v-if="competenza.Competenza && competenza.Competenza.Visibile" :key="competenza.Id + '-2'" @click="editItem(job, competenza)">
							<v-list-item-content>
								<v-list-item-title>{{ competenza.Competenza ? competenza.Competenza.NomeCompetenza : '#############' }}</v-list-item-title>
								<v-list-item-subtitle v-if="competenza.LivelloAtteso>0">
									<translated-text :section-suffix="transSs">Livello atteso:</translated-text> <v-chip x-small outlined color="grey" class="text-uppercase">
										{{ decodificaEtichettaLivelloCompetenza(competenza.LivelloAtteso) }}
									</v-chip>
								</v-list-item-subtitle>
								<v-list-item-subtitle v-else>
									<translated-text :section-suffix="transSs">Livello atteso: no</translated-text>
								</v-list-item-subtitle>
								<!--
								<v-list-item-subtitle v-if="competenza.LivelloAtteso>0">Livello atteso: <v-chip pill x-small class="text-uppercase">{{ competenza.LivelloAttesoDesc }}<v-avatar size="16" right :color="competenza.LivelloAttesoTbs + ' competenzaAttesa'"></v-avatar></v-chip></v-list-item-subtitle>
								<v-list-item-subtitle v-else>Livello atteso: <v-chip pill x-small class="text-uppercase">nessuno<v-avatar x-small right :color="competenza.LivelloAttesoTbs + ' competenzaAttesa'"></v-avatar></v-chip></v-list-item-subtitle>
								-->
							</v-list-item-content>
							<hr-persona-valutazioni-show :valutazioni="getValutazioni(job, competenza.CodiceCompetenza)" :competenza="competenza" />
						</v-list-item>
						<v-divider :key="competenza.Id+ '-3'" />
					</template>
				</v-list>
				<!-- 2° bis - ciclo diretto sulle valutazioni extra job -->
				<v-simple-table v-if="!job">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left" v-text="gTranslate('Data', {sectionSuffix:transSs})"></th>
								<th class="text-left" v-text="gTranslate('Competenza', {sectionSuffix:transSs})"></th>
								<th class="text-left" v-text="gTranslate('Valutazione', {sectionSuffix:transSs})"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="valutazione in valutazioniExtraJob" :key="valutazione.Id + 'exj'" @click="editItem(null, decodificaCompetenza(valutazione.CodiceCompetenza))">
								<td>{{ gStandardDate(valutazione.DataValutazione) }}</td>
								<td>{{ decodificaNomeCompetenza(valutazione.CodiceCompetenza) }}</td>
								<td>
									<v-chip x-small :color="decodificaColoreLivelloCompetenza(valutazione.CodiceValutazione)" class="text-uppercase">
										{{ decodificaEtichettaLivelloCompetenza(valutazione.CodiceValutazione) }}
									</v-chip>
								</td>
								<td>
									<v-btn icon x-small title="Cancella valutazione extra job" @click.stop="delValutazioneExtraJob(valutazione)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>					
			</div>
		</div>

		
		<div class="mt-16"><v-btn v-if="!readonly" text color="secondary" @click="rinnovareValutazioni"><v-icon left>mdi-history</v-icon> <translated-text :section-suffix="transSs">Rinnova tutte le valutazioni</translated-text></v-btn></div>


		<debug-container>
			<v-row>
				<v-col cols="6">
					<debug-panel label="BINDING (lista valutazioni della persona corrente)">
						{{ value }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="jobsList">
						{{ jobsList }}
					</debug-panel>
				</v-col>
			</v-row>
		</debug-container>

		<!-- inizio dialog - edit valutazione -->
		<v-dialog v-if="currentValutazione" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-if="readonly">{{ gTranslate('Visualizza valutazione', {sectionSuffix:transSs}) }} </v-toolbar-title>
					<v-toolbar-title v-else>{{ currentValutazione.Id === 0 ? gTranslate('Nuova valutazione', {sectionSuffix:transSs}) : gTranslate('Modifica valutazione', {sectionSuffix:transSs}) }} </v-toolbar-title>
					<v-spacer />
					<v-toolbar-items v-if="readonly">
						<v-btn text dark @click="dialog = false" v-text="gTranslate('Chiudi', {sectionSuffix:transSs})" />
					</v-toolbar-items>
					<v-toolbar-items v-else>
						<v-btn text dark :disabled="!currentValutazione.CodiceCompetenza" @click="updateItem" v-text="currentValutazione.Id === 0 ? gTranslate('Aggiungi', {sectionSuffix:transSs}) : gTranslate('Modifica', {sectionSuffix:transSs})" />
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-form :readonly="readonly">
						<v-row :dense="compactVisualization">
							<v-col v-if="$vuetify.breakpoint.name === 'xs'" cols="12" lg="12">
								<span class="headline">{{ personaCorrente.FullName }}</span>
							</v-col>
							<v-col cols="12" lg="12">
								<span class="title">{{ currentJobDesc }} {{ currentCompetenzaDesc ? '/' : '' }} {{ currentCompetenzaDesc }}</span>
							</v-col>
						</v-row>
						<v-row :dense="compactVisualization">
							<template v-if="currentValutazione.CodiceJob && !readonly">
								<v-col v-if="currentValutazione.Id !== 0 || currentValutazione.ForceNew" cols="12" lg="12">
									<v-switch v-model="currentValutazione.ForceNew" :label="gTranslate('Forza la creazione di una nuova valutazione', {sectionSuffix:transSs})" @change="forceNewValutazione" />
								</v-col>
								<v-col v-if="currentValutazione.ForceNew" cols="12" lg="12">
									<v-alert text type="info" v-text="gTranslate('Forzando la creazione di una nuova valutazione la precedente verrà preservata e diventerà visibile nello storico', {sectionSuffix:transSs})"></v-alert>
								</v-col>
							</template>
							<v-col v-if="!currentCompetenzaDesc" cols="12" lg="12">
								<v-autocomplete v-model="currentValutazione.CodiceCompetenza" auto-select-first :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Competenza', {sectionSuffix:transSs})" :items="competenze" item-text="NomeCompetenza" item-value="CodiceCompetenza" />
							</v-col>
							<v-col cols="12" lg="6">
								<hr-date-picker v-model="currentValutazione.DataValutazione" :readonly="readonly" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Data valutazione', {sectionSuffix:transSs})" />
							</v-col>
							<v-col cols="12" lg="6">
								<v-select v-model="currentValutazione.CodiceValutazione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Codice valutazione', {sectionSuffix:transSs})" :items="decodifiche.LivelliCompetenzePerBind" item-text="Label" item-value="Codice" item-color="Color" />
								<div v-if="currentCompetenzaDesc && currentCodiceLivelloAtteso>0" class="text-right pr-1">
									<translated-text :section-suffix="transSs">Livello atteso:</translated-text> <v-chip x-small outlined color="grey" class="text-uppercase">
										{{ decodificaEtichettaLivelloCompetenza(currentCodiceLivelloAtteso) }}
									</v-chip>
								</div>
							</v-col>
						</v-row>
					</v-form>

					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12" lg="6">
								<debug-panel label="Current Valutazione">
									{{ currentValutazione }}
								</debug-panel>
							</v-col>
							<v-col cols="12" lg="6">
								<debug-panel label="LivelliCompetenzePerBind">
									{{ decodifiche.LivelliCompetenzePerBind }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
				<v-footer v-if="currentStoricoValutazioni">
					<v-row>
						<v-col cols="12">
							<span class="subtitle" v-text="gTranslate('Valutazioni precedenti', {sectionSuffix:transSs})"></span>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left" v-text="gTranslate('Data', {sectionSuffix:transSs})"></th>
											<th class="text-left" v-text="gTranslate('Codice', {sectionSuffix:transSs})"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in currentStoricoValutazioni" :key="item.Id">
											<td>{{ gStandardDate(item.DataValutazione) }}</td>
											<td>
												<v-chip x-small :color="decodificaColoreLivelloCompetenza(item.CodiceValutazione)" class="text-uppercase white--text">
													{{ decodificaEtichettaLivelloCompetenza(item.CodiceValutazione) }}
												</v-chip>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-footer>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->	

		<v-overlay :value="rinnovoValutazioniLoading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>	
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la pagina di edit PERSONA - gestisce le valutazioni nella tab omonima *****
***********************************************************************************************
***********************************************************************************************
* TODO: documentare meglio i vari passaggi che tra qualche mese non ti ricordi una beata fava
***********************************************************************************************
*/
import Vue from 'vue'
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
import HrPersonaValutazioniShow from '@/components/hr-persona-valutazioni-show'
import HrDatePicker from '@/components/hr-date-picker'
import moment from 'moment'
export default {
	name: 'HrPersonaValutazioni',
	components: { DebugContainer, DebugPanel, HrPersonaValutazioniShow, HrDatePicker },
	props: {
		// la proprietà bindata direttamente (ovvero legata al v-model) corrisponde a "ValutazioniList" ed è appunto l'unica che verrà modificata dal componente e "restituita" alla pagina persona
		value: {
			type: Array,
			required: true,
			default: () => {return []}
		},
		jobsList: {
			type: Array,
			required: true,
			default: () => {return []}
		},
		// disattiva la possibilità di modificare i dati
		readonly: {
			type: Boolean,
			default: () => {return false}
		},		
	},
	data: () => {
		return {
			transSs:'.tabValutazioni',
			dialog: false,
			// variabili di appoggio per l'editing
			currentValutazione: {},
			currentJobDesc: '',
			currentCompetenzaDesc: '',
			currentCodiceLivelloAtteso: 0,
			currentStoricoValutazioni: [],
			rinnovoValutazioniLoading: false,
		}
	},
	computed: {
		appSetup() { return window.setup },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		persone() { return this.$store.getters['persone'] },
		personaCorrente() { return this.$store.getters['personaCorrente'] },
		competenze() { return this.$store.getters['competenze'] },
		// recupera della personaCorrente l'elenco dei jobs e ci aggiunge un job "nullo" per le valutazioni extrajob
		jobsListExt() { 
			var dummy = JSON.parse(JSON.stringify(this.jobsList)) // disaccoppia dallo store altrimenti non è possibile alterare la lista (todo: valutare se farlo server side e buonanotte)
			dummy.push(null)
			return dummy
		},
		// filtra le valutazioni extrajob della persona (quelle con codiceJob=null)
		valutazioniExtraJob() {
			return this.value.filter(item => {
				return !item.CodiceJob
			})
		},
	},
	mounted() {
	},
	methods: {
		// recupera l'oggetto Job a partire dal suo codice
		decodificaJob(codiceJob) {
			if(!codiceJob) return {DescrizioneJob: this.gTranslate('Valutazioni extra Job', {sectionSuffix:this.transSs})}
			return this.decodifiche.Jobs.find(e => e.CodiceJob === codiceJob)
		},
		decodificaCompetenza(codiceCompetenza) {
			return this.competenze.find(e => e.CodiceCompetenza === codiceCompetenza)
		},
		// estrae solo la descrizione delle competenza (con un check di esistenza che non si sa mai)
		decodificaNomeCompetenza(codiceCompetenza) {
			var dummy = this.competenze.find(e => e.CodiceCompetenza === codiceCompetenza)
			if(dummy) return dummy.NomeCompetenza
			return '-'
		},
		// filtra le competenze richieste  per un dato Job
		competenzePerJob(codiceJob) {
			// prima filtra per job sulla tabella di connessione tra competenze e job
			// otteniamo cosi una lista contenente l'elenco dei codici di compenza e altri dati utili (livello atteso, peso, ecc)
			var cpj = this.decodifiche.JobsCompetenze.filter(e => {
				return e.CodiceJob === codiceJob
			})
			// ora cicliamo il risultato e decodifichiamo le competenze
			cpj.forEach(item => {
				item.Competenza = this.decodificaCompetenza(item.CodiceCompetenza)
			})
			// per finire filtriamo solo quelli ove la decodifica della competenza è riuscita
			return cpj.filter(item => { return item.Competenza })
		},
		// restitusce tutte le valutazioni per il job e la competenza richieste
		getValutazioni(codiceJob, codiceCompetenza) {
			// ricordo che le valutazioni arrivano via binding direttamente dalla pagina persona
			return this.value.filter(item => {
				return item.CodiceJob === codiceJob && item.CodiceCompetenza === codiceCompetenza
			})
		},
		// restituisce l'ultima valutazione inserita per il job e la competenza richieste
		getLastValutazione(codiceJob, codiceCompetenza) {
			var dummy = this.getValutazioni(codiceJob, codiceCompetenza)
			return dummy ? dummy[0] : null
		},
		// restituisce lo storico delle valutazioni precedenti
		getPrevValutazioni(codiceJob, codiceCompetenza) {
			var dummy = this.getValutazioni(codiceJob, codiceCompetenza)
			if(!dummy || dummy.length<2) return null
			dummy.splice(0,1)
			return dummy
		},
		decodificaEtichettaLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Livello'+livello]
			} 
			return null
		},
		decodificaColoreLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Colore'+livello]
			} 
			return null
		},
		// nuovo record valutazione
		getNewValutazione(codiceJob, codiceCompetenza, forceNew) {
			return {
				'Id': 0,
				'IdPersona': this.personaCorrente.Id,
				'CodiceCompetenza': codiceCompetenza,
				'CodiceJob': codiceJob,
				'CodiceValutazione': 0,
				'DataValutazione': moment().format('YYYY-MM-DD') + 'T00:00:00',
				'ForceNew': forceNew
			}
		},
		// edit per l'aggiunta di una valutazione extra job
		addValutazioneExtraJob() {
			//this.currentValutazione = {}
			Vue.set(this, 'currentValutazione', this.getNewValutazione(null, null, false))
			this.currentCodiceLivelloAtteso = 0
			this.currentJobDesc = this.decodificaJob(null).DescrizioneJob
			this.currentCompetenzaDesc = null
			this.currentStoricoValutazioni = null
			this.openDialog()
		},
		delValutazioneExtraJob(item) {
			var descCompetenza = this.decodificaNomeCompetenza(item.CodiceCompetenza)
			var dataValutazione = this.gStandardDate(item.DataValutazione)
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Sei sicuro di voler eliminare la valutazione extra job ', {sectionSuffix: this.transSs})
			this.$swal(swTitle, swText + ' "'+dataValutazione+'" / "'+descCompetenza+'" ?', 'warning', {
				buttons: {
					ok: swYes,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					// solito loop su value fino a trovare l'id e poi splice
					var index = -1
					for(var ii=0; ii<this.value.length; ii++) if(this.value[ii].Id === item.Id) index = ii
					// console.warn('REMOVE incdex '+ index)
					this.value.splice(index,1)
				}
			}).catch((error) => {
				console.error(error)
			})
		},
		// apre la modale è imposta l'autofocus
		openDialog() {
			this.dialog = true
			/*
			setTimeout(() => {
				var afdummy = this.$refs.autofocus
				if (afdummy) afdummy.focus()
			}, 300)
			*/
		},
		// edit
		editItem(codiceJob, competenza) {
			var codiceCompetenza = competenza.CodiceCompetenza
			var dummy = this.getLastValutazione(codiceJob, codiceCompetenza)
			var isNew = !dummy
			Vue.set(this, 'currentValutazione', isNew ? {} : JSON.parse(JSON.stringify(dummy)))
			this.currentCodiceLivelloAtteso = competenza.LivelloAtteso
			this.currentJobDesc = this.decodificaJob(codiceJob).DescrizioneJob
			this.currentCompetenzaDesc = this.decodificaCompetenza(codiceCompetenza).NomeCompetenza
			this.currentStoricoValutazioni = this.getPrevValutazioni(codiceJob, codiceCompetenza)
			if(isNew) {
				Vue.set(this, 'currentValutazione', this.getNewValutazione(codiceJob, codiceCompetenza, false))
			} else {
				Vue.set(this.currentValutazione, 'ForceNew', false) // il flag ForceNew serve in fase di salvataggio per azzerare l'Id e forzare quindi la creazione di un nuovo record
			}
			this.openDialog()
		},
		// quando lo switch di force new viene attivato o disattivato cambiamo di conseguenza la struttura di currentValutazione
		forceNewValutazione() {
			var codiceJob = this.currentValutazione.CodiceJob
			var codiceCompetenza = this.currentValutazione.CodiceCompetenza
			if (this.currentValutazione.ForceNew) {
				// forza nuovo sbiancando un po di campi compresi id e data
				Vue.set(this, 'currentValutazione', this.getNewValutazione(codiceJob, codiceCompetenza, true))
			} else {
				// se invece viene diasattivato recupero lo stato precedente
				Vue.set(this, 'currentValutazione', JSON.parse(JSON.stringify(this.getLastValutazione(codiceJob, codiceCompetenza))))
				Vue.set(this.currentValutazione, 'ForceNew', false) // il flag ForceNew serve in fase di salvataggio per azzerare l'Id e forzare quindi la creazione di un nuovo record
			}
		},
		// esegue il salvataggio della valutazione nuova o modificata
		async updateItem() {
			if(this.currentValutazione.Id===0) {
				// nuova valutazione 
				// cambiamo l'id con valiori casuali negativi perché altrimenti se viene ri-editata si apre come nuova invece che come modificata (in fase di slavataggio .net i valori negativi vengono comunque considerati come 0)
				var negativeId = 0
				await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id })
				this.currentValutazione.Id = negativeId
				this.value.unshift(this.currentValutazione)
			} else {
				// valutazione modificata
				// itero l'array Value e quando trovo la valutazione con l'id (this.currentValutazione.Id) lo sostituisco
				this.value.forEach((item, index) => {
					if (item.Id === this.currentValutazione.Id) {
						Vue.set(this.value, index, this.currentValutazione)
					}
				})
			}
			this.dialog = false
		},
		// rinnova tutte le valutazioni
		rinnovareValutazioni() {
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swOk =  this.gTranslate('Ok', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Proseguendo tutte le valutazioni verranno rinnovate con la data odierna. Se necessario, sarà possibile modificarle prima del salvataggio finale.', {sectionSuffix: this.transSs})
			this.$swal(swTitle, swText, 'info', {
				buttons: {
					ok: swOk,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					this.rinnovoValutazioniLoading = true
					setTimeout(() => {
						Vue.nextTick().then(() => {
							this.rinnovaTutteLeValutazioni().then(() => {
								this.rinnovoValutazioniLoading = false
							})
						})
					}, 100)
				}
			}).catch((error) => {
				console.error(error)
			})
		},
		// rinnova tutte le valutazionio seguendo le stesse regole della visualizzazione: itera per prima cosa sui job, ricava le competenze e infine le valutazioni
		async rinnovaTutteLeValutazioni() {
			let negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id })
			return new Promise((resolve) => {
				let jobList = JSON.parse(JSON.stringify(this.jobsList))
				jobList.forEach((job) => {
					let competenzePerJob = this.competenzePerJob(job)
					competenzePerJob.forEach((competenza) => {
						let valutazioneDaRinnovare = this.getLastValutazione(job, competenza.CodiceCompetenza)
						if(valutazioneDaRinnovare && competenza.Competenza.Visibile) {
							let valutazioneRinnovata = JSON.parse(JSON.stringify(valutazioneDaRinnovare))
							valutazioneRinnovata.Id = --negativeId
							// console.warn('valutazioneRinnovata.Id', valutazioneRinnovata.Id) 
							valutazioneRinnovata.DataValutazione =  moment().format('YYYY-MM-DD') + 'T00:00:00',
							// console.warn('valutazione da rinnovare - competenza ' + competenza.CodiceCompetenza + ' per job ' + competenza.CodiceJob, valutazioneDaRinnovare)
							Vue.nextTick().then(() => {
								this.value.unshift(valutazioneRinnovata)
							})
						}
					})
				})
				resolve()
			})
		}
	}
}
</script>

<style scoped lang="less">
</style>