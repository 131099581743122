<template>
	<standard-container notranslate :title="nuovaPersona ? '' : persona.Cognome + ' ' + persona.Nome" description="" :avatar-image="persona.Immagine" :avatar-name="nuovaPersona ? '' : persona.Cognome + ' ' + persona.Nome" :goback="!currentUser.IsGuest">
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab v-if="!currentUser.IsTrainingManager" key="tabBase">
					{{ gTranslate ('Dati base', {key:'tabBase'}) }}
				</v-tab>
				<v-tab v-if="!currentUser.IsTrainingManager && !setup.HidePrivacyData" key="tabAssunzione">
					{{ gTranslate ('Assunzione', {key:'tabAssunzione'}) }}
				</v-tab>
				<v-tab v-if="!currentUser.IsTrainingManager && setup.VisibilitaTabInfoContrattuali && !setup.HidePrivacyData" key="tabInfoContrattuali">
					{{ gTranslate ('Info contrattuali', {key:'tabInfoContrattuali'}) }}
				</v-tab>
				<v-tab v-if="!nuovaPersona && !currentUser.IsTrainingManager && setup.VisibilitaTabInfoPersonali && !setup.HidePrivacyData" key="tabInfoPersonali">
					{{ gTranslate ('Info personali', {key:'tabInfoPersonali'}) }}
				</v-tab>
				<v-tab v-if="!nuovaPersona && !currentUser.IsTrainingManager && !setup.HidePrivacyData" key="tabValutazioni">
					{{ gTranslate ('Valutazioni', {key:'tabValutazioni'}) }}
				</v-tab>
				<v-tab v-if="!nuovaPersona && !currentUser.IsTrainingManager && !setup.HidePrivacyData" key="tabAudit">
					{{ gTranslate ('Audit', {key:'tabAudit'}) }}
				</v-tab>
				<v-tab v-if="!nuovaPersona" key="tabFormazione">
					{{ gTranslate ('Formazione', {key:'tabFormazione'}) }}
				</v-tab>
				<v-tab v-if="!nuovaPersona && !currentUser.IsTrainingManager && setup.VisibilitaTabDocumenti && !setup.HidePrivacyData" key="tabDocumenti">
					{{ gTranslate ('Documenti', {key:'tabDocumenti'}) }}
				</v-tab>
				<v-tab v-if="currentUser.IsAdmin" key="tabGruppi" class="warning--text">
					<v-icon color="warning" small left>
						mdi-settings
					</v-icon>
					{{ gTranslate ('Gruppi', {key:'tabGruppi'}) }}
				</v-tab>
			</v-tabs>
		</template>
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- ********* BASE ******************************************************************************* -->
					<v-tab-item v-if="!currentUser.IsTrainingManager" key="tabBase">
						<v-form :readonly="persona.IsReadOnly">
							<v-row :dense="compactVisualization">
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
										:label="gTranslate ('Nome', {sectionSuffix:'.tabBase'})" 
										required 
										:rules="[ruleCampoObbligatorio]" 
										:hint="persona.Nome ? '' : gTranslate ('Campo obbligatorio!', {section: 'rules'})" 
										persistent-hint 
									/>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.Cognome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
										:label="gTranslate ('Cognome', {sectionSuffix:'.tabBase'})" 
										required 
										:rules="[ruleCampoObbligatorio]" 
										:hint="persona.Cognome ? '' : gTranslate ('Campo obbligatorio!', {section: 'rules'})" 
										persistent-hint 
									/>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.CodiceFiscale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
										:label="gTranslate ('Codice fiscale', {sectionSuffix:'.tabBase'})" 
										persistent-hint
									/>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
										:label="gTranslate ('Email', {sectionSuffix:'.tabBase'})" 
										required 
										:rules="[ruleCampoObbligatorio, ruleEmail]" 
										:hint="persona.Email ? '' : gTranslate ('Campo obbligatorio!', {section: 'rules'})" 
										persistent-hint
									/>
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.Cellulare" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Cellulare', {sectionSuffix:'.tabBase'})" />
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.CellulareAziendale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Cellulare Aziendale', {sectionSuffix:'.tabBase'})" />
								</v-col>

								<v-col cols="12" md="6">
									<v-text-field v-model="persona.UltimaQualificaTitoloDiStudio" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Ultima qualifica o titolo di studio posseduto', {sectionSuffix:'.tabBase'})" />
								</v-col>
								<v-col v-if="!nuovaPersona" cols="6" md="3">
									<v-text-field v-model="persona.AnzianitaAziendaleExt" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Anzianità aziendale', {sectionSuffix:'.tabBase'})" disabled />
								</v-col>
								<v-col v-if="!nuovaPersona" cols="6" md="3">
									<v-text-field v-model="persona.AnzianitaLavorativaExt" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Anzianità lavorativa', {sectionSuffix:'.tabBase'})" disabled />
								</v-col>
								<v-col cols="12" md="12">
									<v-select v-model="persona.SediOperativeList" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" chips small-chips multiple :label="gTranslate ('Sedi', {sectionSuffix:'.tabBase'})" :items="decodifiche.Sedi.filter(sede => { return sede.Visibile })" item-text="DescrizioneSede" item-value="CodiceSede" />
								</v-col>
								<v-col cols="12" md="12">
									<v-autocomplete v-model="persona.JobsList" auto-select-first :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" chips small-chips multiple :label="gTranslate ('Posizione aziendale', {sectionSuffix:'.tabBase'})" :items="decodifiche.Jobs" item-text="DescrizioneJob" item-value="CodiceJob" />
								</v-col>
								<v-col cols="12" md="12">
									<v-autocomplete v-model="persona.RepartoGruppoList" auto-select-first :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" chips small-chips multiple :label="gTranslate ('Reparto o gruppo di lavoro di appartenenza (collaboratori)', {sectionSuffix:'.tabBase'})" :items="decodifiche.RepartiGruppi" item-text="DescrizioneRepartoGruppo" item-value="CodiceRepartoGruppo" />
								</v-col>
								<v-col cols="12" md="12">
									<v-textarea v-model="persona.StoriaProfessionale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Storia professionale in azienda', {sectionSuffix:'.tabBase'})" />
								</v-col>
								<v-col cols="12" md="12">
									<div class="d-flex align-center">
										<span class="title mr-5 primary--text">{{ gTranslate ('Foto', {sectionSuffix:'.tabBase'}) }}</span><v-divider />
									</div>
									<div v-if="persona.HasImmagine">
										<v-row :dense="compactVisualization">
											<v-col cols="12" lg="3" md="4" sm="6">
												<div style="position:relative" class="mb-10">
													<v-btn v-if="!persona.IsReadOnly" fab absolute bottom right small color="error" :title="gTranslate ('Cancella fotografia', {sectionSuffix:'.tabBase'})" @click="cancellaFoto">
														<v-icon>mdi-delete</v-icon>
													</v-btn>
													<v-img :src="imagesPathPerson + persona.Immagine + '?width=800'" class="elevation-5" />
												</div>
											</v-col>
											<v-col cols="12" lg="9" md="8" sm="6" />
										</v-row>
									</div>
									<div v-else>
										<v-row v-if="!persona.IsReadOnly" :dense="compactVisualization">
											<v-col cols="12" lg="6" md="8">
												<v-btn v-if="!panelNuovaFoto" color="warning" small @click="aggiungiFoto">
													<v-icon left>
														mdi-plus
													</v-icon> {{ gTranslate ('Aggiungi foto', {sectionSuffix:'.tabBase'}) }}
												</v-btn>
												<div v-else class="d-flex align-center">
													<v-file-input v-model="dataFileFoto" accept="image/*" label="Foto" show-size @change="uploadNuovaFoto" />
													<v-btn class="ml-5" small color="warning" :disabled="!dataFileFoto || uploadingNuovaFoto" :loading="uploadingNuovaFoto" @click="uploadNuovaFoto">
														{{ gTranslate ('Carica foto', {sectionSuffix:'.tabBase'}) }}
													</v-btn>
													<v-btn class="ml-5" small @click="panelNuovaFoto = false">
														{{ gTranslate ('Cancella foto', {sectionSuffix:'.tabBase'}) }}
													</v-btn>
													<v-alert v-if="fotoErrorDetail" text>
														{{ fotoErrorDetail }}
													</v-alert>
												</div>
											</v-col>
										</v-row>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>
					<!-- ********* ASSUNZIONE ******************************************************************************* -->
					<v-tab-item v-if="!currentUser.IsTrainingManager && !setup.HidePrivacyData" key="tabAssunzione">
						<v-form :readonly="persona.IsReadOnly">
							<v-row :dense="compactVisualization">
								<v-col cols="12" md="6">
									<hr-date-picker v-model="persona.DataDiAssunzione" :readonly="persona.IsReadOnly" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Data di assunzione', {sectionSuffix:'.tabAssunzione'})" />
								</v-col>
								<v-col cols="12" md="6">
									<hr-date-picker v-model="persona.DataInizioLavoro" :readonly="persona.IsReadOnly" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Data inizio lavoro', {sectionSuffix:'.tabAssunzione'})" />
								</v-col>
								<v-col cols="12" md="12">
									<v-combobox v-model="persona.MotivoAssunzione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="JSON.parse(decodifiche.MotiviAssunzione)" :label="gTranslate ('Motivo di assunzione', {sectionSuffix:'.tabAssunzione'})" />
								</v-col>
								<v-col cols="12" md="12">
									<v-autocomplete v-model="persona.ReferentiColloquioList" auto-select-first :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" chips small-chips multiple :label="gTranslate ('Colloquio di assunzione fatto da', {sectionSuffix:'.tabAssunzione'})" :items="persone" item-text="FullName" item-value="Id" />
								</v-col>
								<v-col cols="12" md="12">
									<v-select v-model="persona.AreaInserimentoList" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" chips small-chips multiple :label="gTranslate ('Area inserimento', {sectionSuffix:'.tabAssunzione'})" :items="decodifiche.AreeInserimento" item-text="DescrizioneAreaInserimento" item-value="CodiceAreaInserimento" />
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>
					<!-- ********* INFO CONTRATTUALI *************************************************************************** -->
					<v-tab-item v-if="!currentUser.IsTrainingManager && setup.VisibilitaTabInfoContrattuali && !setup.HidePrivacyData" key="tabInfoContrattuali">
						<v-form :readonly="persona.IsReadOnly">
							<v-row :dense="compactVisualization">
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.Matricola" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Matricola', {sectionSuffix:'.tabInfoContrattuali'})" />
								</v-col>
								<v-col cols="12" md="6">
									<v-select v-model="persona.CodiceCategoriaLivello" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Categoria/Livello', {sectionSuffix:'.tabInfoContrattuali'})" :items="decodifiche.CategoriaLivelloInquadramento" item-text="DescrizioneLivello" item-value="CodiceLivello" />
								</v-col>
								<v-col cols="12" md="6">
									<v-text-field v-model="persona.RAL" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('RAL', {sectionSuffix:'.tabInfoContrattuali'})" />
								</v-col>
								<v-col cols="12" md="6">
									<v-select v-model="persona.Qualifica" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Qualifica', {sectionSuffix:'.tabInfoContrattuali'})" :items="JSON.parse(decodifiche.Qualifiche)" />
								</v-col>
								<v-col cols="12" md="6">
									<v-switch v-model="persona.MBO" :label="gTranslate ('MBO - ', {sectionSuffix:'.tabInfoContrattuali'}) + persona.MBO" false-value="NO" true-value="SI" />
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>
					<!-- ********* INFO PERSONALI ******************************************************************************* -->
					<v-tab-item v-if="!nuovaPersona && !currentUser.IsTrainingManager && setup.VisibilitaTabInfoPersonali && !setup.HidePrivacyData" key="tabInfoPersonali">
						<v-form :readonly="persona.IsReadOnly">
							<v-row :dense="compactVisualization">
								<v-col cols="6" md="2">
									<v-select v-model="persona.Sesso" :items="decodifiche.Sesso" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Sesso', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="6" md="4">
									<v-select v-model="persona.TitoloStudio" :items="decodifiche.TitoliDiStudio" item-value="key" item-text="value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Titolo di studio', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="6" md="4">
									<hr-date-picker v-model="persona.DataDiNascita" :readonly="persona.IsReadOnly" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Data di nascita', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>							
								<v-col v-if="persona.Eta > 0" cols="6" md="2">
									<v-text-field v-model="persona.EtaExt" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Età', {sectionSuffix:'.tabInfoPersonali'})" disabled />
								</v-col>						
								<v-col cols="8" lg="6">
									<v-text-field v-model="persona.Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Indirizzo', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="4" lg="2">
									<v-text-field v-model="persona.DistanzaDallaSede" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Distanza dalla sede (km)', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="6" md="4">
									<v-select v-model="persona.LivelloConoscenzaInglese" :items="decodifiche.LivelloConoscenzaInglese" item-value="key" item-text="value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Livello conoscenza inglese', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="6" md="4">
									<v-select v-model="persona.ValutazioneSulPotenziale" :items="decodifiche.ValutazioniSulPotenziale" item-value="key" item-text="value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Valutazione sul potenziale', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="6" md="4">
									<v-select v-model="persona.LivelloEngagement" :items="decodifiche.LivelliEngagement" item-value="key" item-text="value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Livello di engagement', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="12">
									<v-textarea v-model="persona.SituazioneFamiliare" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate ('Situazione familiare', {sectionSuffix:'.tabInfoPersonali'})" />
								</v-col>
								<v-col cols="12">
									<v-divider></v-divider>
								</v-col>
								<v-col cols="12">
									<hr-persona-mappa v-model="persona"></hr-persona-mappa>
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>
					<!-- ********* VALUTAZIONI ******************************************************************************* -->
					<v-tab-item v-if="!nuovaPersona && !currentUser.IsTrainingManager && !setup.HidePrivacyData" key="tabValutazioni">
						<v-row :dense="compactVisualization">
							<v-col cols="12" md="12">
								<hr-persona-valutazioni v-model="persona.ValutazioniList" :jobs-list="persona.JobsList" :readonly="persona.IsReadOnly" />	
							</v-col>
						</v-row>
					</v-tab-item>
					<!-- ********* AUDITS ******************************************************************************* -->
					<v-tab-item v-if="!nuovaPersona && !currentUser.IsTrainingManager && !setup.HidePrivacyData" key="tabAudit">
						<v-row :dense="compactVisualization">
							<v-col cols="12" md="12">
								<hr-persona-audits v-model="persona.AuditsList" :readonly="persona.IsReadOnly" />
							</v-col>
						</v-row>
					</v-tab-item>
					<!-- ********* FORMAZIONE ******************************************************************************* -->
					<v-tab-item v-if="!nuovaPersona" key="tabFormazione">
						<v-tabs v-model="formazioneTab" :right="true">
							<v-tab key="tabFormazioneCorsi">
								{{ gTranslate ('Corsi', {sectionSuffix:'.tabFormazione'}) }}
							</v-tab>
							<v-tab key="tabFormazioneRichieste">
								{{ gTranslate ('Richieste di formazione', {sectionSuffix:'.tabFormazione_Richieste'}) }}
							</v-tab>
						</v-tabs>
						<v-tabs-items v-model="formazioneTab">
							<v-tab-item key="tabFormazioneCorsi">
								<v-row :dense="compactVisualization">
									<v-col cols="12" md="12">
										<hr-persona-formazione v-model="persona.FormazionePersoneCorsiList" :readonly="persona.IsReadOnly" />
									</v-col>
								</v-row>
							</v-tab-item>
							<v-tab-item key="tabFormazioneRichieste">
								<v-row :dense="compactVisualization">
									<v-col cols="12" md="12">
										<hr-persona-formazione-richieste v-model="persona.FormazioneRichieste" :readonly="persona.IsReadOnly" />
									</v-col>
								</v-row>
							</v-tab-item>
						</v-tabs-items>
					</v-tab-item>
					<!-- ********* DOCUMENTI ******************************************************************************* -->
					<v-tab-item v-if="!nuovaPersona && !currentUser.IsTrainingManager && setup.VisibilitaTabDocumenti && !setup.HidePrivacyData" key="tabDocumenti">
						<v-row :dense="compactVisualization">
							<v-col cols="12" md="12">
								<hr-persona-allegati v-model="persona.AllegatiList" :readonly="persona.IsReadOnly" />
							</v-col>
						</v-row>
					</v-tab-item>
					<!-- ********* GRUPPI ******************************************************************************* -->
					<v-tab-item v-if="currentUser.IsAdmin" key="tabGruppi">
						<v-row :dense="compactVisualization">
							<v-col cols="12" md="12">
								<div class="headline">
									{{ gTranslate ('Gruppi', {sectionSuffix:'.tabGruppi'}) }}
								</div>
								<div class="mt-5 ml-5">
									<v-row>
										<v-col v-for="item in persona.Gruppi" :key="'gruppo_' + item.NomeGruppo" xl="2" lg="3" md="4" sm="6" cols="12">
											<div class="d-flex align-center" style="height:45px;">
												<div><v-checkbox v-model="item.Appartenenza" :dense="tce.dense" color="warning" class="mr-5" @change="updateGruppiAppartenenza" /></div>
												<div>
													<div>
														<div class="body-2 warning--text">
															{{ item.NomeGruppo }}
														</div>
														<div class="caption">
															{{ item.DescrizioneGruppo }}
														</div>
													</div>
												</div>
											</div>
										</v-col>
									</v-row>
								</div>
							</v-col>
						</v-row>
						<v-divider class="mt-6 mb-6"></v-divider>
						<v-row>
							<v-col cols="12" md="12">
								<v-card :color=" persona.EnableDirectAccess ? (darkTheme ? 'deep-orange darken-4' : 'deep-orange lighten-4') : ''" elevation="4" class="mb-4" max-width="374">
									<v-card-title>{{ gTranslate ('Accesso diretto read-only', {sectionSuffix:'.tabGruppi'}) }}</v-card-title>
									<v-card-subtitle>{{ gTranslate ('Attivazione e disattivazione dell\'accesso diretto degli utenti alla propria scheda.', {sectionSuffix:'.tabGruppi'}) }}</v-card-subtitle>
									<v-card-text>
										<v-row>
											<v-col cols="12"><v-switch v-model="persona.EnableDirectAccess" label="Attiva accesso diretto"></v-switch></v-col>
											<v-col v-if="persona.EnableDirectAccess" cols="12">
												<v-card :disabled="persona.DirectAccessHasChangedPassword">
													<v-card-title>{{ gTranslate ('Password', {sectionSuffix:'.tabGruppi'}) }}:</v-card-title>
													<v-card-text v-if="persona.DirectAccessHasChangedPassword"><span style="font-size:200%">🔐</span></v-card-text>
													<v-card-text v-else><pre style="font-size:200%">{{ persona.DirectAccessPasswordDeafult }}</pre></v-card-text>
												</v-card>
											</v-col>
											<v-col v-if="persona.DirectAccessLastLogin"><span>{{ gTranslate ('Ultimo login effettuato', {sectionSuffix:'.tabGruppi'}) }}: {{ persona.DirectAccessLastLogin }}</span></v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
						<debug-panel>{{ persona.Gruppi }}</debug-panel>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
		
		<template v-slot:footer>
			<v-alert v-if="saveError" text class="mt-5" type="error">
				{{ saveError }}
			</v-alert>
			<v-card v-if="!persona.IsReadOnly" class="mt-5">
				<v-card-text>
					<div class="d-flex justify-end">
						<v-btn large @click="$router.go(-1)">
							{{ gTranslate ('CANCEL', {key:'btn-cancel'}) }}
						</v-btn>
						<v-btn large color="error" class="ml-5" :disabled="!validateForm || !isModified || saving" :loading="saving" @click="salvaPersona">
							{{ gTranslate ('SAVE', {key:'btn-save'}) }}
						</v-btn>
					</div>
				</v-card-text>
			</v-card>
			<debug-panel label="currentUser.IsAdmin" class="mt-5">
				{{ currentUser.IsAdmin }}
			</debug-panel>
			<debug-panel label="setup.HidePrivacyData" class="mt-5">
				{{ setup.HidePrivacyData }}
			</debug-panel>
			<debug-panel label="!nuovaPersona" class="mt-5">
				{{ !nuovaPersona }}
			</debug-panel>
			<debug-panel label="isModified" class="mt-5">
				{{ isModified }}
			</debug-panel>
			<debug-panel label="saved" class="mt-5">
				{{ saved }}
			</debug-panel>
		</template>

		<template v-slot:debug>
			<debug-panel label="persona" class="mt-5">
				{{ persona }}
			</debug-panel>
		</template>

		<loading :loading="saving" />
		<v-snackbar v-model="confermaSalvataggio" bottom color="success" multi-line timeout="4000">
			<h1>{{ gTranslate ('Salvataggio completato') }}</h1>
		</v-snackbar>
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import HrDatePicker from '@/components/hr-date-picker'
import HrPersonaValutazioni from '@/components/hr-persona-valutazioni'
import HrPersonaAudits from '@/components/hr-persona-audits'
import HrPersonaAllegati from '@/components/hr-persona-allegati'
import HrPersonaFormazione from '@/components/hr-persona-formazione'
import HrPersonaFormazioneRichieste from '@/components/hr-persona-formazione-richieste'
import HrPersonaMappa from '@/components/hr-persona-mappa'
import DebugPanel from '@/components/debug-panel'
import Loading from '@/components/loading'
// import DebugContainer from '@/components/debug-container'
import lodash from 'lodash'
export default {
	name: 'PersonaPage',
	components: { StandardContainer, HrDatePicker, HrPersonaValutazioni, HrPersonaAudits, HrPersonaAllegati, HrPersonaFormazione, HrPersonaFormazioneRichieste, HrPersonaMappa, DebugPanel, Loading },
	data: () => {
		return {
			currentTab: null,
			formazioneTab: 0,
			persona: {},
			personaPrev: {},
			inputDataDiAssunzione: null,
			panelNuovaFoto: false,
			uploadingNuovaFoto: false,
			dataFileFoto: null,
			fotoErrorDetail: '',
			confermaSalvataggio: false, 
			saving: false, // flag salvataggio in corso
			saved: false, // flag salvataggio avvenuto
			saveError: '',
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		personeExitAfterSave() { return this.$store.getters['personeExitAfterSave'] },
		imagesPathPerson() { return this.$store.getters['imagesPathPerson'] },
		setup() { return this.$store.getters['setup'] },
		currentUser() { return this.$store.getters['currentUser'] },
		darkTheme() { return this.$vuetify.theme.dark },
		decodifiche() { return this.$store.getters['decodifiche'] },
		persone() { return this.$store.getters['persone'] },
		gruppiUtenti() { return this.$store.getters['gruppiUtenti'] },
		personaCorrente() { return this.$store.getters['personaCorrente'] },
		nuovaPersona() { return this.persona.Id === 0},
		// confronta la struttura corrente con un suo duplicato memorizzato all'avvio per capire se sono state fatte modifiche 
		isModified() { return ! lodash.isEqual(this.persona, this.personaPrev) },
		// validazione minimale della form, ovvero che almeno siano compilati i campi essenziali
		validateForm() { return this.persona.Nome && this.persona.Cognome && this.persona.Email }
	},
	mounted() {
		this.init()
	},
	// avvisa l'utente se ci sono modifiche non salvate al refresh o alla chiusura del tab/browser
	created() {
		window.addEventListener('beforeunload', (event) => {
			event.preventDefault()			
			if(this.isModified && !this.saved) {
				(event || window.event).returnValue = ''
				return ''
			} else {
				delete event['returnValue']
			}
		})
	},
	// avvisa l'utente se ci sono modifche non salvate alla variazione di route (todo: gestire anche la chiusura pagina)
	beforeRouteLeave (to, from, next) {
		if(this.isModified && !this.saved) {
			/*
			const answer = window.confirm('Attenzione, ci sono delle modifiche non salvate, se abbandoni la pagina verranno perse!')
			if (answer) {
				next()
			} else {
				next(false)
			}
			*/
			var alertSaveTitle = this.gTranslate('ATTENZIONE!', {section: 'swal'})
			var alertSaveText = this.gTranslate('Ci sono delle modifiche non salvate, se abbandoni la pagina verranno perse!', {key:'alertSaveText'})
			var alertSaveButtonCancel = this.gTranslate('Esci senza salvare', {key:'alertSaveButtonCancel'})
			var alertSaveButtonOk = this.gTranslate('SALVA', {key:'alertSaveButtonOk'})
			this.$swal(alertSaveTitle, alertSaveText, 'warning', { 
				buttons: { 
					esci: {
						text: alertSaveButtonCancel,
						value: 0,
					}, 
					salva: {
						text: alertSaveButtonOk,
						value: 1
					}
				}}).then(result => {
				// console.warn('resultqqqq',result)
				if (result == 1) {
					// se l'utente opta per il salvataggio questo viene effettuato e poi si esce 
					this.salvaPersona().then(() => {
						next()	
						// console.warn('uscita con salvataggio')
					}).catch(() => {
						// console.warn('no uscita con salvataggio ERRORE')
						next(false)	// se il salvataggio va in errore ovviamente non si esce
					})
				} else if (result == 0) {
					//next(false)
					// se l'utente opta per l'uscuita senza salvare si esce e basta
					// console.warn('uscita no salvataggio')
					next()
				} else {
					// gestisce il caso in cui un utente clicchi fuori dello swal!
					next(false)
				}
			}).catch((error) => {
				console.error(error)
				next(false)
			})			
		} else {
			// console.warn('uscita senza nulla da salvare')
			next()
		}
	},
	methods: {
		// 
		init() {
			// disaccoppia l'oggetto persona da quello restituito nella pagina persone e salvato nello store 
			// lo fa perché 1° non si può modificare direttametne un oggetto dello store e 2° perché così non si modifica nulla fino al salvataggio
			this.persona = JSON.parse(JSON.stringify(this.personaCorrente))
			
			// aggiunge un campo Gruppi alla persona (ricordo che con la #836 questo non arriva più dalla api) e lo valorizza con una copia della struttura dei gruppi
			this.$set(this.persona, 'Gruppi', JSON.parse(JSON.stringify(this.gruppiUtenti)))
			// valorizza il flag "Appartenenza" del nuovo campo Gruppi identificando quelli il cui nome compare in GruppiAppartenenza
			this.persona.Gruppi.forEach(item => {
				var dummy = this.persona.GruppiAppartenenza ? this.persona.GruppiAppartenenza.find(subitem => { return subitem === item.NomeGruppo}) : null
				item.Appartenenza = !!dummy
			})

			// aggiunge un campo IsReadOnly alla persona e lo collega allo stato guest dell'utente loggato (#96) - in futuro si potrebbe gestire meglio
			this.$set(this.persona, 'IsReadOnly', this.currentUser.IsGuest)


			// crea un clone di persona che servirà confrontare i due oggetti e veerificare se il primo è stato modificato (e quindi abilitare il save per esempio)
			this.personaPrev = JSON.parse(JSON.stringify(this.persona)) 
			this.saved = false
		},
		// elimina la foto corrente
		cancellaFoto() {
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			var alertPhotoDeleteText = this.gTranslate('Sei sicuro di voler eliminare la foto di', {key:'alertPhotoDeleteText'})
			this.$swal(swTitle, alertPhotoDeleteText + ' "'+this.persona.FullName+'" ?', 'warning', 
				{ buttons: { ok: swYes, cancel: swNo }}
			).then(result => {
				if (result === 'ok') {
					this.persona.Immagine = null
					this.persona.HasImmagine = false
				}
			}).catch((error) => {
				console.error(error)
			})
		},
		// apre pannello per aggiunta nuova foto
		aggiungiFoto() {
			this.panelNuovaFoto = true
		},
		// carica nuova foto
		uploadNuovaFoto() {
			this.uploadingNuovaFoto = true
			let formData = new FormData()
			formData.append('fotoData', this.dataFileFoto)
			this.$store.dispatch('uploadFoto', formData).then(immagine => {
				// l'api di upload ci restituisce direttamente il path del file identico a quello della versione legacy
				this.uploadingNuovaFoto = false
				this.panelNuovaFoto = false
				this.persona.Immagine = immagine
				this.persona.HasImmagine = true
			}).catch(error => {
				console.error(error)
				this.error = this.gTranslate('Errore durante il caricamento del file', {key:'alertPhotoLoadError'})
				if (error.response && error.response.data) {
					if(error.response.data.InnerException){
						if(error.response.data.InnerException.InnerException) {
							this.fotoErrorDetail = error.response.data.InnerException.InnerException.ExceptionMessage
						} else {
							this.fotoErrorDetail = error.response.data.InnerException.ExceptionMessage
						}
					} else {
						if(error.response.data.ExceptionMessage) {
							this.fotoErrorDetail = error.response.data.ExceptionMessage
						} else {
							this.fotoErrorDetail = error.response.data.Message
						}
					}
				}
				this.uploadingNuovaFoto = false
			}) 
		},
		// aggiorna la GruppiAppartenenza al variare della gruppi
		updateGruppiAppartenenza() {
			this.persona.GruppiAppartenenza = this.persona.Gruppi.filter( gruppo => { return gruppo.Appartenenza }).map( gruppo => { return gruppo.NomeGruppo })
		},
		// salva la persona e torna alla lista
		salvaPersona() {
			return new Promise((resolve, reject) => {
				this.saveError = ''
				this.saving = true
				this.$store.dispatch('savePersona', this.persona).then((personaSalvata) => {
					this.saved = true
					this.saving = false
					this.confermaSalvataggio = true
					if(this.personeExitAfterSave) {
						// e poi torna alla lista
						this.$router.go(-1) //.push({name: 'persone'})
					} else {
						// #998 replica le procedure di caricamento pagina caricando la struttura della persona appena salvata (quindi con tutti gli id al posto giusto!!)
						this.$store.commit('EDIT_PERSONA', personaSalvata)
						// questo equivale al mount della pagina
						this.init()
					}
					resolve()
				}).catch(error => {
					this.saveError = error
					this.saving = false
					reject(error)
				})
			})
		},
	}	
}
</script>

<style scoped lang="less">



</style>