<template>
	<div>
		<div class="d-flex justify-space-between align-baseline mb-5">
			<hr-anno-riferimento v-model="annoCorrente" :upper-limit="annoRiferimentoIniziale+1" :lower-limit="annoRiferimentoIniziale-1" />		
			<v-btn v-if="!readonly" color="warning" small class="" title="" @click="addItem">
				<v-icon left>
					mdi-plus
				</v-icon> {{ gTranslate('nuovo', {sectionSuffix:transSs}) }} 
			</v-btn>
		</div>	
			

		<div v-if="!visualizzaInterfaccia">
			<v-alert border="left" type="info">
				{{ gTranslate('Lo storico è visibile solo nella pagina', {sectionSuffix:transSs}) }}  <v-btn small link text to="/formazione">
					<v-icon small>
						mdi-arrow-right
					</v-icon>{{ gTranslate('Formazione', {sectionSuffix:transSs}) }} 
				</v-btn>
			</v-alert>
		</div>
		<div v-else>
			<div class="d-flex mb-4">
				<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" :label="gTranslate('Ricerca', {sectionSuffix:transSs})" hide-details clear-icon="mdi-close-circle" clearable />
				<v-select v-model="groupby" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" class="ml-5" :label="gTranslate('Raggruppa per', {sectionSuffix:transSs})" :items="raggruppamenti" hide-details clear-icon="mdi-close-circle" clearable />
			</div>
			
			<v-data-table dense
				:group-by.sync="groupby"
				:search="search"
				:loading="loading"
				:items="formazioneConDecodifiche" 
				:headers="gTranslateTableHeader(tableHeader, {sectionSuffix:transSs})"
				:sort-by="['DataInizio']" 
				:sort-desc="[false]"
				item-key="Id"
				disable-pagination
				hide-default-footer
				@click:row="editItem"
			>
				<template v-slot:item.Id="{ item }">
					<v-chip x-small label :color="item.CorsoColore" class="pl-0" />
				</template>
				<template v-slot:item.DocenteNome="{ item }">
					<div class="d-flex justify-space-between">
						<standard-label>{{ item.DocenteNome }}</standard-label>
						<div v-if="item.DocenteNome!==0" class="ml-2">
							<v-chip v-if="item.DocenteTipo" color="info" x-small label>
								{{ gTranslate('interno', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-else color="" x-small label>
								{{ gTranslate('esterno', {sectionSuffix:transSs}) }}
							</v-chip>
						</div>
					</div>
				</template>
				<template v-slot:item.DataInizio="{ item }">
					<standard-label>{{ gShortDate(item.DataInizio) }}</standard-label>
				</template>
				<template v-slot:item.DataFine="{ item }">
					<standard-label>{{ gShortDate(item.DataFine) }}</standard-label>
				</template>
				<template v-slot:item.Finanziato="{ item }">
					<standard-label>{{ item.Finanziato ? 'Y': '-' }}</standard-label>
				</template>
				<template v-slot:item.CorsoSuperato="{ item }">
					<v-icon v-if="item.CorsoSuperato" color="success">
						mdi-check-decagram
					</v-icon>
					<v-icon v-else class="opaque2">
						mdi-check-decagram
					</v-icon>
				</template>
				<template v-slot:item.ValutazioneCorso="{ item }">
					<v-rating dense readonly :value="item.ValutazioneCorso" :background-color="$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2 '" :color="$vuetify.theme.dark ? 'white' : 'grey darken-1'" />
				</template>	
				<template v-slot:item.Delete="{ item }">
					<v-btn v-if="!readonly" text icon x-small color="warning" :title="gTranslate('Elimina data corso', {sectionSuffix:transSs})" @click.stop="deleteItem(item)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>						
			</v-data-table>
		</div>			

		<debug-container>
			<v-row>
				<v-col cols="6">
					<debug-panel label="value">
						{{ value }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="decoupledValue">
						{{ decoupledValue }}
					</debug-panel>
				</v-col>
				<v-col cols="12" lg="12">
					<debug-panel label="decodifiche.FormazioneCorsi">
						{{ decodifiche.FormazioneCorsi }}
					</debug-panel>
				</v-col>				
			</v-row>
		</debug-container>

		<!-- inizio dialog -->
		<v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-text="currentDataCorso.Id<0 ? gTranslate('Nuovo corso', {sectionSuffix:transSs}) : gTranslate('Modifica corso', {sectionSuffix:transSs})" />
					<v-spacer />
					<v-toolbar-items><v-btn text dark :disabled="!currentDataCorso.IdCorso || !currentDataCorso.IdDataCorso" @click="updateItem" v-text="currentDataCorso.Id<0 ? gTranslate('Aggiungi', {sectionSuffix:transSs}) : gTranslate('Modifica', {sectionSuffix:transSs})" /></v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col v-if="$vuetify.breakpoint.name === 'xs'" cols="12" lg="12">
							<span class="headline">{{ personaCorrente.FullName }}</span>
						</v-col>
					</v-row>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="12">
							<v-autocomplete v-model="currentDataCorso.IdCorso" auto-select-first :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
								:label="gTranslate('Job - Corso', {sectionSuffix:transSs})" 
								:items="corsiFiltratiPerJob" 
								item-value="Id" 
								:item-text="(i) => {return decodificaNomeJob(i.IdJob) + ' - ' + i.Codice }" 
								@change="loadDate"
							/>
						</v-col>
						<v-col cols="12" lg="12">
							<v-autocomplete v-model="currentDataCorso.IdDataCorso" auto-select-first :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" 
								:label="gTranslate('Date del corso', {sectionSuffix:transSs})" 
								:items="datePerCorsoCorrente" 
								item-value="Id" 
								:item-text="(i) => {return '' + gShortDate(i.Inizio) + ' / ' + gShortDate(i.Fine) + ' - ' + i.Nome + '' }" 
								@change="loadDate"
							/>
						</v-col>
						<v-col cols="12" lg="6">
							<label class="small-label">{{ gTranslate('Valutazione', {sectionSuffix:transSs}) }}</label>
							<v-rating v-model="currentDataCorso.ValutazioneCorso" dense x-large clearable background-color="rgba(255,152,0, 0.2)" color="orange" />
						</v-col>
						<v-col cols="12" lg="3">
							<label class="small-label">{{ gTranslate('Corso superato', {sectionSuffix:transSs}) }}</label>
							<v-switch v-model="currentDataCorso.CorsoSuperato" class="mt-2" />
						</v-col>
						<v-col cols="12" lg="3">
							<label class="small-label">{{ gTranslate('Ore effettuate', {sectionSuffix:transSs}) }}</label>
							<v-text-field v-model="currentDataCorso.OreEffettuate" type="number" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
					</v-row>

					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12" lg="6">
								<debug-panel label="currentDataCorso">
									{{ currentDataCorso }}
								</debug-panel>
								<debug-panel label="datePerCorsoCorrente" class="mt-3">
									{{ datePerCorsoCorrente }}
								</debug-panel>
							</v-col>
							<v-col cols="12" lg="6">
								<debug-panel label="decodifiche.FormazioneCorsi">
									{{ decodifiche.FormazioneCorsi }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->	
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la pagina di edit PERSONA - gestisce la formazione nella tab omonima ******
***********************************************************************************************
*/
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
import HrAnnoRiferimento from '@/components/hr-anno-riferimento'
// import HrDatePicker from '@/components/hr-date-picker'
import StandardLabel from '@/components/standard-label'
import moment from 'moment'
import lodash from 'lodash'
export default {
	name: 'HrPersonaFormazione',
	components: { DebugContainer, DebugPanel, HrAnnoRiferimento, StandardLabel },
	props: {
		// la proprietà bindata direttamente (ovvero legata al v-model) corrisponde a "FormazionePersoneCorsiList" ed è appunto l'unica che verrà modificata dal componente e "restituita" alla pagina persona
		value: {
			type: Array,
			required: true,
			default: () => {return []}
		},
		// disattiva la possibilità di modificare i dati
		readonly: {
			type: Boolean,
			default: () => {return false}
		},			
	},
	data: () => {
		return {
			transSs:'.tabFormazione',
			decoupledValue: [],
			annoRiferimentoIniziale: parseInt(moment().format('YYYY')),
			annoCorrente: parseInt(moment().format('YYYY')),
			dialog: false,
			currentDataCorso: {},
			datePerCorsoCorrente: [],
			loading: false,
			search: '',
			groupby: null,
			tableHeader: [
				{ text: '', value: 'Id' },
				{ text: 'Corso', value: 'CodiceCorso', groupable: true },
				{ text: 'Job', value: 'JobNome', groupable: true },
				{ text: 'Finalità', value: 'FinalitaFormazione', groupable: true },
				{ text: 'Docente', value: 'DocenteNome', groupable: true },
				{ text: 'Sede', value: 'SedeNome', groupable: true },
				{ text: 'Trimestre', value: 'Trimestre', groupable: true },
				{ text: 'Nome data', value: 'NomeDataCorso', groupable: true },
				{ text: 'Inizio', value: 'DataInizio' },
				{ text: 'Fine', value: 'DataFine' },
				// { text: 'Ore t.', value: 'OreData' },
				{ text: 'Ore e.', value: 'OreEffettuate' },
				// { text: 'Costo', value: 'Costo' },
				// { text: 'Finanziato', value: 'Finanziato', groupable: true },
				{ text: 'Superato', value: 'CorsoSuperato', groupable: true },
				{ text: 'Feedback corso', value: 'ValutazioneCorso', groupable: true },
				{ text: '', value: 'Delete' },
			],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		persone() { return this.$store.getters['persone'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		personaCorrente() { return this.$store.getters['personaCorrente'] },
		// vincola la visualizzazione al solo anno corrente + 1
		visualizzaInterfaccia() { return this.annoCorrente == this.annoRiferimentoIniziale || this.annoCorrente == (this.annoRiferimentoIniziale + 1) || this.annoCorrente == (this.annoRiferimentoIniziale - 1) },
		// filtra i corsi disponibili per i job della persona corrente
		corsiFiltratiPerJob() {
			// #865 (disabilitato filtro job)
			var abilitaFiltroJob = false
			if(abilitaFiltroJob) {
				// array di codici (occhio non id) dei jobs
				var personaJobCodes = this.personaCorrente.JobsList 
				// ci servonno gli id non i cazzo di codici dei job, grrrrr
				var personaJobs = this.decodifiche.Jobs.filter( i => {
					return  personaJobCodes.indexOf(i.CodiceJob) != -1 
				})
				var corsiFiltrati = this.decodifiche.FormazioneCorsi.filter(i => {
					return personaJobs.find( j => { return j.Id === i.IdJob }) || i.IdJob === 0
				})
				return corsiFiltrati
			} else {
				return this.decodifiche.FormazioneCorsi
			}
		},
		// decodifica i dati di value (ovvero lista formazione) per una visualizzazione più agevole e inoltre li filtra per annoRiferimento
		decodedList() {
			this.$set(this, 'decoupledValue', JSON.parse(JSON.stringify(this.value)))
			this.decoupledValue.forEach(i => {
				// decodifica e aggiunge l'oggetto datacorso al quale sono stati aggiunti due campi di appoggio, per il successivio filtraggio, contenenti l'anno di inizio e di fine della data corso
				// aggiunto un check che consente di procedere anche se l'utente ha delle date corso non valide (fuori range)
				if(!i.IdDataCorso) {
					console.error('i.IdDataCorso non valida', i)
					this.$set(i, 'Valid', false)
				} else {
					let dataCorsoOriginal = this.decodifiche.FormazioneCorsiDate.find(j => { return j.Id === i.IdDataCorso})
					if(!dataCorsoOriginal) {
						// console.warn('DataCorso non trovata (fuori range) per i.IdDataCorso = ', i.IdDataCorso)
						this.$set(i, 'Valid', false)
					} else {
						var dataCorso = JSON.parse(JSON.stringify(dataCorsoOriginal)) 
						this.$set(dataCorso, 'AnnoInizio', moment(dataCorso.Inizio).year())
						this.$set(dataCorso, 'AnnoFine', moment(dataCorso.Fine).year())
						this.$set(i, 'DataCorso', dataCorso)
						this.$set(i, 'Valid', true)
					}
				}
			})
			// filtra le date corso in modo che compaiano solo quelle contenti (o a cavallo) dell'anno selezionato
			let filteredList = this.decoupledValue.filter(i => {
				return i.DataCorso && i.DataCorso.Corso && (i.DataCorso.AnnoInizio === this.annoCorrente || i.DataCorso.AnnoFine === this.annoCorrente || (i.DataCorso.AnnoInizio < this.annoCorrente && i.DataCorso.AnnoFine > this.annoCorrente))
			})
			filteredList = lodash.orderBy(filteredList, 'DataCorso.Inizio')
			return filteredList
		},
		// questo secondo passaggio di decodifica è stato aggiunto per la #980 ed è derivato direttamente dalla pagina formazione, ecco perché non è stato integrato in decodedList
		formazioneConDecodifiche() {
			var formazione = JSON.parse(JSON.stringify(this.decodedList))
			formazione.forEach(item => {
				// decodifica persona - solo i campi necessari per non far esplodere il browser! :-)
				var persona = this.persone.find(i => {return i.Id === item.IdPersona})
				this.$set(item, 'PersonaFullName', persona ? persona.FullName: item.IdPersona) // se non trova la persona ne visualizza il codice
				if (item.DataCorso && item.DataCorso.Corso) {
					// recupera date corso e altri dati che non richiedono decodifiche
					this.$set(item, 'IdCorso', item.DataCorso.Corso.Id ) 
					this.$set(item, 'CodiceCorso', item.DataCorso.Corso.Codice ) 
					this.$set(item, 'DescrizioneCorso', item.DataCorso.Corso.Descrizione ) 
					this.$set(item, 'NomeDataCorso', item.DataCorso.Nome ) 
					this.$set(item, 'DataInizio', item.DataCorso.Inizio ) 
					this.$set(item, 'DataFine', item.DataCorso.Fine ) 
					this.$set(item, 'CorsoColore', item.DataCorso.Corso.Colore ) 
					this.$set(item, 'Finanziato', item.DataCorso.Corso.Finanziato ) 
					this.$set(item, 'OreData', item.DataCorso.OreData ) 
					// costo viene preso da datacorso e, se non disponibile, da corso
					var costo = item.DataCorso.CostoData && item.DataCorso.CostoData>0 ? item.DataCorso.CostoData : item.DataCorso.Corso.Costo
					this.$set(item, 'Costo', costo) 
					// decodifica trimestre
					var trimestre = this.decodifiche.FormazioneTrimestre.find(i => {return i.Key===item.DataCorso.Trimestre})
					this.$set(item, 'Trimestre', trimestre ? trimestre.Value : item.DataCorso.Trimestre ) 
					// decodifica finalità
					var finalita = this.decodifiche.FormazioneFinalita.find(i => {return i.Key==item.DataCorso.Corso.CodiceFinalita})
					this.$set(item, 'FinalitaFormazione', finalita ? finalita.Value : item.DataCorso.Corso.CodiceFinalita ) 
					// decodifica job
					var idJob = item.DataCorso.Corso.IdJob
					var job = this.decodifiche.Jobs.find(i => {return i.Id === idJob})
					this.$set(item, 'JobNome', job ? job.DescrizioneJob : ( idJob<=0 ? '*' : idJob) ) // se non trova il job ne visualizza il codice if(idJob<=0) return '*'
					// decodifica docente
					var idDocente = item.DataCorso.IdDocente
					if (idDocente<=0) idDocente = item.DataCorso.Corso.IdDocentePrincipale
					var docente = this.decodifiche.FormazioneCorsiDocenti.find(i => {return i.Id === idDocente})
					this.$set(item, 'DocenteNome', docente ? docente.Nome : idDocente )
					this.$set(item, 'DocenteDescrizione', docente ? docente.Descrizione : idDocente )
					this.$set(item, 'DocenteTipo', docente ? docente.Interno : false )
					// decodifica sede
					var idSede = item.DataCorso.IdSede
					if (idSede<=0) idSede = item.DataCorso.Corso.IdSedePrincipale
					var sede = this.decodifiche.FormazioneCorsiSedi.find(i => {return i.Id === idSede})
					this.$set(item, 'SedeNome', sede ? sede.Nome : idSede )
					this.$set(item, 'SedeDescrizione', sede ? sede.Descrizione : idSede )
					// alla fine sbianca la subcollection per ridurre il peso della lista
					item.DataCorso = {}
				} else {
					// se manca la struttura corso visualizza le decodifiche con un trattino
					this.$set(item, 'CodiceCorso', '-' )
					this.$set(item, 'DescrizioneCorso', '-' )
					this.$set(item, 'CorsoColore', '#000' )
					this.$set(item, 'FinalitaFormazione', '-')
					this.$set(item, 'JobNome', '-' ) 
					this.$set(item, 'DocenteNome', '-' )
					this.$set(item, 'DocenteDescrizione', '-' )
					this.$set(item, 'DocenteTipo', false )
					this.$set(item, 'SedeNome', '-' )
					this.$set(item, 'SedeDescrizione', '-' )
				}
			})
			return formazione
		},
		raggruppamenti() {
			var result = []
			this.tableHeader.forEach(element => {
				if(element.groupable) result.push(element)	
			})
			return result
		},
	},
	mounted() {
	},
	methods: {
		decodificaNomeDocente(dataCorso) {
			var idDocente = dataCorso.IdDocente
			if(!idDocente || idDocente === 0) idDocente = dataCorso.Corso.IdDocentePrincipale
			var docente = this.decodifiche.FormazioneCorsiDocenti.find(i => {return i.Id === idDocente})
			if(docente) return docente.Nome
			return '-'
		},
		decodificaTipoDocente(dataCorso) {
			var idDocente = dataCorso.IdDocente
			if(!idDocente || idDocente === 0) idDocente = dataCorso.Corso.IdDocentePrincipale
			var docente = this.decodifiche.FormazioneCorsiDocenti.find(i => {return i.Id === idDocente})
			if(docente) return docente.Interno
			return false
		},
		decodificaNomeSede(dataCorso) {
			var idSede = dataCorso.IdSede
			if(!idSede || idSede === 0) idSede = dataCorso.Corso.IdSedePrincipale
			var sede = this.decodifiche.FormazioneCorsiSedi.find(i => {return i.Id === idSede})
			if(sede) return sede.Nome
			return '-'
		},
		// recupera il nome del Job a partire dal suo id
		decodificaNomeJob(idJob) {
			if(idJob<=0) return '*'
			var job = this.decodifiche.Jobs.find(e => e.Id === idJob)
			if (job) return job.DescrizioneJob
			return '-'
		},
		// aggiunta nuovo item
		async addItem() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			var item = {
				Id: negativeId,
				IdPersona: this.personaCorrente.Id,
				IdDataCorso: 0,
				ValutazioneCorso:0,
				OreEffettuate:0
			}
			this.editItem(item)
		},
		// edit item (apertura modale)
		editItem(item) {
			if(!this.readonly){
				// console.warn('editItem', item)
				this.$set(this, 'currentDataCorso', JSON.parse(JSON.stringify(item)))
				// aggiunge un campo di appoggio per la gestione delle tendina del corso
				//var idCorso = this.currentDataCorso.DataCorso ? this.currentDataCorso.DataCorso.Corso.Id : 0 
				//this.$set(this.currentDataCorso, 'IdCorso', idCorso)
				// con l'idCorso appena recuperato effettua il caricamento della tendina delle date
				this.loadDate()
				// sbianca le subcollection che da qui in avanti non servono e fanno solo casino
				this.currentDataCorso.DataCorso = null
				//
				this.dialog = true
			}
		},
		// elimina un record
		deleteItem(itemToDelete) {
			var idx = -1
			this.value.forEach((item, index) => {
				if (item.Id === itemToDelete.Id) {
					idx = index
					return 
				}
			})
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Sei sicuro di voler rimuovere la partecipazione al corso', {sectionSuffix: this.transSs})
			this.$swal(swTitle, swText + ' '+itemToDelete.CodiceCorso+'" ?', 'warning', {
				buttons: {
					ok: swYes,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					this.value.splice(idx,1)
				}
			}).catch((error) => {
				console.error(error)
			})	
		},
		// nella modale, viene scatenato alla variazione della tendina corso, va a ricaricare la datePerCorsoCorrente che è a sua votla bindata alla tendina delle date
		loadDate() {
			var corso = this.decodifiche.FormazioneCorsi.find(i => {return i.Id === this.currentDataCorso.IdCorso})
			if(corso) this.datePerCorsoCorrente = corso.Date
		},
		// "emit" dato aggiornato (chiusura modale) 
		updateItem() {
			// nulliamo le sub-collection che non servono più ed è inutile propagarle
			this.currentDataCorso.Corso = null
			this.currentDataCorso.DataCorso = null
			// itero l'array Value e se trovo l'item di pari Id lo sostituisco, altrimenti lo unshifto alla fine
			var add = true
			this.value.forEach((item, index) => {
				if (item.Id === this.currentDataCorso.Id) {
					this.$set(this.value, index, this.currentDataCorso)
					add = false
					return 
				}
			})
			if(add) this.value.unshift(this.currentDataCorso)
			this.dialog = false
		},
		// aggiorna la valutazione del corso
		changeValutazione(item, newValue) {
			item.ValutazioneCorso = newValue
			this.$store.dispatch('genericApiPost', {apiUrl: '/Formazione/SetValutazione', value: item}).then(() => {
			}).catch(error => {
				console.error('changeValutazione', error)
			})
		},
	},
}
</script>

<style scoped lang="less">
	.opaque{ 
		opacity: 0.5;
	}
	.opaque2{ 
		opacity: 0.25;
	}
</style>