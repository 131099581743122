<template>
	<v-form :readonly="value.IsReadOnly">
		<v-row>
			<v-col v-if="value.IndirizzoLat>0 && value.IndirizzoLon>0" cols="6">
				<v-text-field v-model="value.IndirizzoLat" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Indirizzo - latitudine', {sectionSuffix:transSs})" />
			</v-col>
			<v-col v-if="value.IndirizzoLat>0 && value.IndirizzoLon>0" cols="6">
				<v-text-field v-model="value.IndirizzoLon" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Indirizzo - longitudine', {sectionSuffix:transSs})" />
			</v-col>
			<v-col v-if="value.IndirizzoLat===0 || value.IndirizzoLon===0" cols="12">
				<h2 color="accent">
					<translated-text :section-suffix="transSs">Coordinate non presenti, spostare il marker sulla mappa</translated-text>
					<a v-if="value.Indirizzo && setup.MapquestGeocodingApiKey" @click="ricavaCoordinateDaIndirizzo"><translated-text :section-suffix="transSs">oppure cliccare qui per provare a ricavarle dall'indirizzo.</translated-text></a>
				</h2>
				<small v-if="value.Indirizzo && !setup.MapquestGeocodingApiKey"><translated-text :section-suffix="transSs">in alternativa, compilando nel setup la chiave 'setup.maps.geocoding.mapquest_api_key', e' possibile provare a dedurle dall'indirizzo.</translated-text></small>
			</v-col>
			<v-col cols="12 pt-0">
				<small v-if="value.IndirizzoLat>0 && value.IndirizzoLon>0 && !value.IsReadOnly"><translated-text :section-suffix="transSs">E' possibile aggiustare le coordinate spostando il marker sulla mappa</translated-text></small>
				<v-responsive :aspect-ratio="16/9">
					<l-map
						v-if="showMap"
						ref="map"
						v-resize="onResize"
						:zoom="zoom"
						:center="center"
						:options="mapOptions"
						style="height: 100%"
					>
						<l-tile-layer
							:url="url"
							:attribution="attribution"
						/>
						<l-marker :lat-lng.sync="indirizzoMarker" :draggable="!value.IsReadOnly">
						</l-marker>
					</l-map>
				</v-responsive>
			</v-col>
		</v-row>	
	</v-form>		
</template>

<script>
/**********************************************************************************************
**** COMPONENTE PER LA GESTIONE DELLA MAPPA RELATIVA ALL'INDIRIZZO DELLA PERSONA **************
***********************************************************************************************
*/

// import moment from 'moment'
// import L from 'leaflet'
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import axios from 'axios'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
	name: 'HrPersonaMappa',
	components: { LMap, LTileLayer, LMarker },
	props: {
		value: {
			type: Object,
			required: true
		},
	},
	data: () => {
		return {
			transSs:'.tabInfoPersonali',
			zoom: 13,
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			mapOptions: {
				zoomSnap: 0.5
			},
			showMap: true
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		setup() { return this.$store.getters['setup'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		center() { return latLng(this.value.IndirizzoLat, this.value.IndirizzoLon) },
		indirizzoMarker: { 
			// getter
			get: function () {
				return latLng(this.value.IndirizzoLat, this.value.IndirizzoLon) 
			},
			// setter
			set: function (newValue) {
				// console.warn('newValue', newValue)
				this.value.IndirizzoLat = newValue.lat
				this.value.IndirizzoLon = newValue.lng
			}
		}
	},
	mounted() {
	},
	methods: {
		onResize() {
			this.$refs.map.mapObject.invalidateSize()
		},
		ricavaCoordinateDaIndirizzo() {
			let mapquestUrl = 'https://www.mapquestapi.com/geocoding/v1/address?key=' + this.setup.MapquestGeocodingApiKey + '&location=' + this.value.Indirizzo + '&thumbMaps=false&maxResults=1'
			axios.get(mapquestUrl).then((result) => {
				// console.warn('ricavaCoordinateDaIndirizzo', result)
				this.value.IndirizzoLat = result.data.results[0].locations[0].latLng.lat
				this.value.IndirizzoLon = result.data.results[0].locations[0].latLng.lng
			})
		},
	},
}
</script>

<style scoped lang="less">
</style>