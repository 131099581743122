<template>
	<div>
		<v-list-item-action class="mr-0">
			<div class="d-flex justify-end">
				<!-- blocco dell'ultima valutazione -->
				<div v-if="ultimaValutazione" class="text-right">
					<span class="caption">{{ gStandardDate(ultimaValutazione.DataValutazione) }}</span><br>
					<v-icon v-if="hasStoricoValutazioni" small color="primary" class="mr-1" :title="gTranslate('Esistono valutazioni precedenti a questa (cliccare per il dettaglio)')">
						mdi-history
					</v-icon> 
					<v-chip x-small :color="decodificaColoreLivelloCompetenza(ultimaValutazione.CodiceValutazione)" class="text-uppercase white--text">
						{{ decodificaEtichettaLivelloCompetenza(ultimaValutazione.CodiceValutazione) }}
					</v-chip>
				</div>
				<!-- blocco delle icone di stato -->
				<div class="ml-10 align-self-end">
					<!-- visualizza asterisco per le valutazioni mancanti (quelle con livello atteso zero si considerano opzionali e qundi non visualizziamo nulla) -->
					<v-icon v-if="competenza.LivelloAtteso > 0 && !ultimaValutazione" large color="warning" :title="gTranslate('Valutazione da eseguire')">
						mdi-asterisk
					</v-icon>
					<!-- se l'ultima valutazione ha un livello maggiore o uguale a quello richiesto la si considera positiva, quindi icona verde -->
					<v-icon v-if="competenza.LivelloAtteso > 0 && ultimaValutazione && (ultimaValutazione.CodiceValutazione >= competenza.LivelloAtteso)" large color="success" :title="gTranslate('Valutazione positiva (livello raggiunto maggiore o uguale a quello atteso)')">
						mdi-check-circle-outline
					</v-icon>
					<!-- se l'ultima valutazione ha un livello minore di quello richiesto la si considera negativa, quindi icona rossa -->
					<v-icon v-if="competenza.LivelloAtteso > 0 && ultimaValutazione && (ultimaValutazione.CodiceValutazione < competenza.LivelloAtteso)" large color="error" :title="gTranslate('Valutazione negativa (livello inferiore a quello atteso)')">
						mdi-alert
					</v-icon>
					<!-- se l'ultima valutazione esiste ed è maggiore di zero e il livello atteso è zero allora mostra un cerchio verde -->
					<v-icon v-if="competenza.LivelloAtteso === 0 && ultimaValutazione && ultimaValutazione.CodiceValutazione > 0" large color="success darken-2" :title="gTranslate('Valutazione effettuata')">
						mdi-checkbox-blank-circle-outline
					</v-icon>
					<!-- se l'ultima valutazione esiste ed è uguale a zero e il livello atteso è zero allora mostra un cerchio rosso -->
					<v-icon v-if="competenza.LivelloAtteso === 0 && ultimaValutazione && ultimaValutazione.CodiceValutazione === 0" large color="error darken-2" :title="gTranslate('Valutazione effettuata')">
						mdi-checkbox-blank-circle-outline
					</v-icon>
				</div>
			</div>
		</v-list-item-action>
	</div>
</template>

<script>
/**********************************************************************************************
**** sottocompnente di hr-valutazioni-persona - serve a visualizzare le singole valutazioni ***
***********************************************************************************************
*/
// import DebugPanel from '@/components/debug-panel'
export default {
	name: 'HrPersonaValutazioniShow',
	components: {  },
	props: {
		// 
		valutazioni: {
			type: Array,
			default: () => {return null}
		},
		competenza: {
			type: Object,
			required: true
		}
	},
	data: () => {
		return {

		}
	},
	computed: {
		decodifiche() { return this.$store.getters['decodifiche'] },
		// restituisce, se presente, l'ultima valutazione
		ultimaValutazione() {
			if(!this.valutazioni || this.valutazioni.length===0) return null
			return this.valutazioni[0]
		},
		// indica la presenza di altre valutazioni (storico)
		hasStoricoValutazioni() {
			if(!this.valutazioni || this.valutazioni.length<=1) return false
			return true
		},
	},
	mounted() {
	},
	methods: {
		decodificaEtichettaLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Livello'+livello]
			} 
			return null
		},
		decodificaColoreLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Colore'+livello]
			} 
			return null
		}
	},
}
</script>

<style scoped lang="less">

</style>