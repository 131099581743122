<template>
	<div>
		<br>
		
		<div class="d-flex justify-end">
			<v-btn color="warning" small class="" title="" @click="addItem"  v-if="!readonly">
				<v-icon left>
					mdi-plus
				</v-icon> {{ gTranslate('nuova richiesta di formazione', {sectionSuffix:transSs}) }} 
			</v-btn>
		</div>

		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<td colspan="3">{{ gTranslate('Richieste correnti', {sectionSuffix:transSs}) }}</td>
						<td></td>
					</tr>
					<tr>
						<th class="text-left">{{ gTranslate('Data', {sectionSuffix:transSs}) }}</th>
						<th class="text-left">{{ gTranslate('Stato', {sectionSuffix:transSs}) }}</th>
						<th class="text-left">{{ gTranslate('Descrizione', {sectionSuffix:transSs}) }}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in value" :key="item.Id" @click="editItem(item)">
						<td class="clickable">{{ gStandardDate(item.DataRichiesta) }}</td>
						<td class="clickable">
							<v-chip v-if="item.StatoRichiestaFormazione===10" label small color="primary">
								{{ gTranslate('nuova', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="item.StatoRichiestaFormazione===20" label small color="warning">
								{{ gTranslate('in valutazione', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="item.StatoRichiestaFormazione===30" label small color="success">
								{{ gTranslate('programmata', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="item.StatoRichiestaFormazione===40" label small color="success">
								{{ gTranslate('effettuata', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="item.StatoRichiestaFormazione===50" label small color="error">
								{{ gTranslate('rifiutata', {sectionSuffix:transSs}) }}
							</v-chip>
						</td>
						<td class="clickable">
							{{ item.DescrizioneRichiesta | truncate(70) }}
						</td>
						<td>
							<v-btn v-if="item.StatoRichiestaFormazione===10 && !readonly" x-small :title="gTranslate('Elimina', {sectionSuffix:transSs})" class="ml-5" icon color="warning" @click.stop="deleteItem(item)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<debug-container>
			<v-row>
				<v-col cols="6">
					<debug-panel label="BINDING (lista richieste della persona corrente)">
						{{ value }}
					</debug-panel>
				</v-col>
				<v-col cols="6" />
			</v-row>
		</debug-container>

		<!-- inizio dialog -->
		<v-dialog v-if="currentRichiesta" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title v-text="currentRichiesta.Id<0 ? gTranslate('Nuova richiesta', {sectionSuffix:transSs}) : gTranslate('Modifica richiesta', {sectionSuffix:transSs})" />
					<v-spacer />
					<v-toolbar-items>
						<v-btn v-if="currentRichiesta.StatoRichiestaFormazione===10" text dark @click="updateItem" v-text="currentRichiesta.Id<0 ? gTranslate('Aggiungi', {sectionSuffix:transSs}) : gTranslate('Modifica', {sectionSuffix:transSs})" />
						<v-btn v-if="currentRichiesta.StatoRichiestaFormazione>10" text dark @click="dialog = false" v-text="gTranslate('chiudi', {sectionSuffix:transSs})" />
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col v-if="$vuetify.breakpoint.name === 'xs'" cols="12" lg="12">
							<span class="headline">{{ personaCorrente.FullName }}</span>
						</v-col>
					</v-row>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="12">
							<v-textarea ref="autofocus" v-model="currentRichiesta.DescrizioneRichiesta" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione richiesta" :disabled="currentRichiesta.StatoRichiestaFormazione!==10" />
						</v-col>
						<v-col v-if="currentRichiesta.StatoRichiestaFormazione>10" cols="12" lg="12">
							{{ gTranslate('Stato', {sectionSuffix:transSs}) }}: 
							<v-chip v-if="currentRichiesta.StatoRichiestaFormazione===20" label color="warning">
								{{ gTranslate('in valutazione', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="currentRichiesta.StatoRichiestaFormazione===30" label color="success">
								{{ gTranslate('programmata', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="currentRichiesta.StatoRichiestaFormazione===40" label color="success">
								{{ gTranslate('effettuata', {sectionSuffix:transSs}) }}
							</v-chip>
							<v-chip v-if="currentRichiesta.StatoRichiestaFormazione===50" label color="error">
								{{ gTranslate('rifiutata', {sectionSuffix:transSs}) }}
							</v-chip>
						</v-col>
						<v-col v-if="currentRichiesta.StatoRichiestaFormazione>20" cols="12" lg="12">
							<div>
								<small><i>{{ gStandardDate(currentRichiesta.DataRisposta) }}</i></small>
							</div>
							<div>
								<code style="margin-top:20px; margin-bottom:20px; padding:10px; display:block">{{ currentRichiesta.DescrizioneRisposta }}</code>
							</div>
						</v-col>
					</v-row>

					<debug-container>
						<v-row :dense="compactVisualization" />
					</debug-container>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->			
	</div>
</template>

<script>
/**********************************************************************************************
**** Componente per la pagina di edit PERSONA - gestisce le richieste di formazione ***********
***********************************************************************************************
***********************************************************************************************
* TODO: documentare meglio i vari passaggi che tra qualche mese non ti ricordi una beata fava -> e infatti non mi ricordo, cazzo!!
***********************************************************************************************

 Le nuove richieste di formazione (stato 10:"nuovo") contengono solo la descrizione e restano editabili da parte degli utenti che vedono la persona
 al passaggio di stato (20:"in valutazione") l'editing del campo descrizione non è più possibile
 dallo stato 30 ("Programmata") in poi vengono visualizzati anche i campi DataRisposta e DescrizioneRisposta (Sempre non editabili)

*/
import Vue from 'vue'
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
// import HrDatePicker from '@/components/hr-date-picker'
import moment from 'moment'
export default {
	name: 'HrPersonaFormazioneRichieste',
	components: { DebugContainer, DebugPanel },
	props: {
		// la proprietà bindata direttamente (ovvero legata al v-model) corrisponde a "RichiesteFormazione" ed è appunto l'unica che verrà modificata dal componente e "restituita" alla pagina persona
		value: {
			type: Array,
			required: true,
			default: () => {return []}
		},
		// disattiva la possibilità di modificare i dati
		readonly: {
			type: Boolean,
			default: () => {return false}
		},			
	},
	data: () => {
		return {
			transSs:'.tabFormazioneRichieste',
			dialog: false,
			// richiesta correntemente visualizzata nella modale
			currentRichiesta: {},
		}
	},
	computed: {
		appSetup() { return window.setup },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		persone() { return this.$store.getters['persone'] },
		personaCorrente() { return this.$store.getters['personaCorrente'] },
		currentUser() { return this.$store.getters['currentUser'] },
	},
	mounted() {
	},
	methods: {
		decodificaStatoRichiestaFormazione(id) {
			if(this.decodifiche && this.decodifiche.StatoRichiestaFormazione) {
				var dummy = this.decodifiche.StatoRichiestaFormazione.find(item => { return item.Id == id }) // ATTENZIONE !!! va usato == e NON === in quanto le due tabelle di origine hanno tipi diversi (Audits.CodiceTipoAudit è string mentre TipoColloquio.Id è un int)
				if(dummy) return dummy.DescrizioneRichiesta
			} 
			return '-'
		},
		// apre la modale è imposta l'autofocus
		openDialog() {
			this.dialog = true
			setTimeout(() => {
				var afdummy = this.$refs.autofocus
				if (afdummy) afdummy.focus()
			}, 300)
		},
		// aggiunge nuovo audit, carica currentRichiesta e apre modale
		async addItem() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			// @,@,@,@,@,@,@IdUtenteRispondente
			this.currentRichiesta = {

				Id: negativeId,
				IdPersona: this.personaCorrente.Id,
				StatoRichiestaFormazione: 10,
				DataRichiesta: moment().format('YYYY-MM-DD') + 'T00:00:00',
				IdUtenteRichiedente: this.currentUser.Id,
				DescrizioneRichiesta: '',
			}
			this.openDialog()
		},
		// modifica richiesta selezionata nella tabella, carica currentRichiesta e apre modale
		editItem(item) {
			if(!this.readonly) {
				Vue.set(this, 'currentRichiesta', JSON.parse(JSON.stringify(item)))
				this.openDialog()
			}
		},
		// "emit" dato aggiornato 
		updateItem() {
			var add = true
			// itero l'array Value e se trovo l'audit di pari Id lo sostituisco
			this.value.forEach((item, index) => {
				if (item.Id === this.currentRichiesta.Id) {
					Vue.set(this.value, index, this.currentRichiesta)
					add = false
					return 
				}
			})
			if(add) this.value.unshift(this.currentRichiesta)
			this.dialog = false
		},
		// elimina un record
		deleteItem(itemToDelete) {
			var idx = -1
			this.value.forEach((item, index) => {
				if (item.Id === itemToDelete.Id) {
					idx = index
					return 
				}
			})
			let swTitle =  this.gTranslate('ATTENZIONE!', {section: 'swal'})
			let swYes =  this.gTranslate('Si', {section: 'swal'})
			let swNo =  this.gTranslate('No', {section: 'swal'})
			let swText =  this.gTranslate('Sei sicuro di voler rimuovere la richiesta di formazione ?', {sectionSuffix: this.transSs})
			this.$swal(swTitle, swText, 'warning', {
				buttons: {
					ok: swYes,
					cancel: swNo
				}
			}).then(result => {
				if (result === 'ok') {
					this.value.splice(idx,1)
				}
			}).catch((error) => {
				console.error(error)
			})	
		},		
	},
}
</script>

<style scoped lang="less">

</style>